import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';

import {
    StyledLabel,
    HiddenRadio,
    StyledRadio,
    RadioLabel,
} from './RadioOption.styles';
import {color} from '../../../styles/variables';
import Icon, {icons} from '../Icon';

const RadioOption = forwardRef((props, ref) => {
    const {
        id,
        name,
        value,
        checked,
        img,
        icon,
        disabled,
        onChange,
        children,
        ...rest
    } = props;
    return (
        <StyledLabel
            htmlFor={id}
            disabled={disabled}
            img={img}
            icon={icon}
            checked={checked}>
            {img && img}
            <HiddenRadio
                {...rest}
                ref={ref}
                id={id}
                name={name}
                value={value}
                disabled={disabled}
                checked={checked}
                onChange={onChange}
            />
            {icon ? (
                <Icon
                    name={icon}
                    size="24px"
                    color={checked ? color.petrol : color.grey}
                />
            ) : (
                <StyledRadio
                    className="ap-custom-radio"
                    checked={checked}
                    disabled={disabled}>
                    {checked && (
                        <Icon name="radio" size="24px" color={color.red} />
                    )}
                </StyledRadio>
            )}
            <RadioLabel className="ap-custom-label" disabled={disabled}>
                {children}
            </RadioLabel>
        </StyledLabel>
    );
});

RadioOption.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    img: PropTypes.any,
    icon: PropTypes.oneOf(Object.keys(icons)),
    onChange: PropTypes.func,
    children: PropTypes.string,
};

RadioOption.defaultProps = {
    checked: false,
};

export default RadioOption;
