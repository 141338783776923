import styled from 'styled-components';

import {color} from '../../../styles/variables';
import Icon from '../Icon';

export const Wrapper = styled.div`
    display: flex;
    /* align-items: center; */
    margin-top: 0.5rem;
`;

export const StyledIcon = styled(Icon)`
    flex: 0 0 20px;
`;

export const StyledError = styled.small`
    color: ${color.wine};
    margin-left: 0.5rem;
`;
