import {css} from 'styled-components';

/**
 * Mixin: normalize default list styles.
 *
 * Returns a css snippet to normalize default list styles.
 *
 * @returns {css}
 */
export const normalizeList = () => {
    return css`
        padding: 0;
        margin: 0;
        list-style: none;
    `;
};
