import {navbarHeight} from '../styles/variables';
import {windowScrollTo} from 'seamless-scroll-polyfill/dist/esm/';

/**
 * Stop scrolling.
 *
 * Fixes the html in a way that the user can't scroll anymore.
 */
export const stopScrolling = () => {
    const html = document.documentElement;
    html.style.overflow = 'hidden';
};

/**
 * Start scrolling.
 *
 * Unfixes the html in a way hat the user can scroll again.
 */
export const startScrolling = () => {
    const html = document.documentElement;
    html.style.overflow = 'auto';
};

export const scrollToTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

export const scrollToAnchorStart = (anchor, e) => {
    const element = window.document.querySelector(
        `[data-scroll-anchor="${anchor}"]`
    );
    if (element) {
        windowScrollTo({
            behavior: 'smooth',
            top:
                element.getBoundingClientRect().top +
                window.pageYOffset -
                parseInt(navbarHeight),
        });
    }
};
