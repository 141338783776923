import {css} from 'styled-components';

import {font} from './variables';

import PTSans400WOFF from '../assets/fonts/pt-sans/pt-sans-v12-latin-regular.woff';
import PTSans700WOFF from '../assets/fonts/pt-sans/pt-sans-v12-latin-700.woff';
import PTSans400WOFF2 from '../assets/fonts/pt-sans/pt-sans-v12-latin-regular.woff2';
import PTSans700WOFF2 from '../assets/fonts/pt-sans/pt-sans-v12-latin-700.woff2';

import PTSerif400WOFF from '../assets/fonts/pt-serif/pt-serif-v12-latin-regular.woff';
import PTSerif700WOFF from '../assets/fonts/pt-serif/pt-serif-v12-latin-700.woff';
import PTSerif400WOFF2 from '../assets/fonts/pt-serif/pt-serif-v12-latin-regular.woff2';
import PTSerif700WOFF2 from '../assets/fonts/pt-serif/pt-serif-v12-latin-700.woff2';

import PTSerifItalic400WOFF from '../assets/fonts/pt-serif/pt-serif-v12-latin-italic.woff';
import PTSerifItalic700WOFF from '../assets/fonts/pt-serif/pt-serif-v12-latin-700italic.woff';
import PTSerifItalic400WOFF2 from '../assets/fonts/pt-serif/pt-serif-v12-latin-italic.woff2';
import PTSerifItalic700WOFF2 from '../assets/fonts/pt-serif/pt-serif-v12-latin-700italic.woff2';

/*
  All modern browsers support WOFF2 which is the most efficient file format; IE11 needs WOFF.
  All other file formats are either dead or not optimized for the web.
*/

export default css`
    @font-face {
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: ${font.weight.regular};
        font-display: fallback;
        src: local('PT Sans'), local('PTSans-Regular'),
            url(${PTSans400WOFF2}) format('woff2'),
            url(${PTSans400WOFF}) format('woff');
    }

    @font-face {
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: ${font.weight.bold};
        font-display: fallback;
        src: local('PT Sans Bold'), local('PTSans-Bold'),
            url(${PTSans700WOFF2}) format('woff2'),
            url(${PTSans700WOFF}) format('woff');
    }

    @font-face {
        font-family: 'PT Serif';
        font-weight: ${font.weight.regular};
        font-display: fallback;
        src: local('PT Serif Regular'), local('PTSerif-Regular'),
            url(${PTSerif400WOFF2}) format('woff2'),
            url(${PTSerif400WOFF}) format('woff');
    }

    @font-face {
        font-family: 'PT Serif';
        font-weight: ${font.weight.bold};
        font-display: fallback;
        src: local('PT Serif Bold'), local('PTSerif-Bold'),
            url(${PTSerif700WOFF2}) format('woff2'),
            url(${PTSerif700WOFF}) format('woff');
    }

    @font-face {
        font-family: 'PT Serif';
        font-style: italic;
        font-weight: ${font.weight.regular};
        font-display: fallback;
        src: local('PT Serif Italic'), local('PTSerif-Italic'),
            url(${PTSerifItalic400WOFF2}) format('woff2'),
            url(${PTSerifItalic400WOFF}) format('woff');
    }

    @font-face {
        font-family: 'PT Serif';
        font-style: italic;
        font-weight: ${font.weight.bold};
        font-display: fallback;
        src: local('PT Serif Bold Italic'), local('PTSerif-BoldItalic'),
            url(${PTSerifItalic700WOFF2}) format('woff2'),
            url(${PTSerifItalic700WOFF}) format('woff');
    }
`;
