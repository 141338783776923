import React from 'react';
import PropTypes from 'prop-types';

import {StyledForm} from './Form.styles';

export {default as Label} from './Label';
export {default as Input} from './Input';
export {default as Textarea} from './Textarea';
export {Textarea as CoreTextarea} from './Textarea';
export {default as Checkbox} from './Checkbox';
export {Checkbox as CheckboxOption} from './Checkbox';
export {default as CheckboxGroup} from './CheckboxGroup';
export {default as RadioOption} from './RadioOption';
export {default as RadioGroup} from './RadioGroup';
export {default as Select} from './Select';
export {default as ErrorMessage} from './ErrorMessage';

const Form = ({children, ...props}) => (
    <StyledForm {...props} className="ap-form">
        {children}
    </StyledForm>
);

Form.propTypes = {
    children: PropTypes.any,
};

export default Form;
