import React from 'react';
import {Navigation} from './BurgerNav.styles';
import MainNav from './MainNav';
import Divider from '../../components/Divider';
import MetaNav from './MetaNav';
import Container from '../Grid/Container';
import PropTypes from 'prop-types';

const BurgerNav = ({location, loggedIn, username, userRoles}) => {
    return (
        <Navigation className="ap-nav ap-nav--burger">
            <Container noGutter>
                <MainNav
                    location={location}
                    userRoles={userRoles}
                    loggedIn={loggedIn}
                />
            </Container>
            <Divider />
            <Container noGutter>
                <MetaNav
                    location={location}
                    loggedIn={loggedIn}
                    username={username}
                    userRoles={userRoles}
                />
            </Container>
        </Navigation>
    );
};

BurgerNav.propTypes = {
    location: PropTypes.object,
    loggedIn: PropTypes.bool,
    username: PropTypes.string,
    userRoles: PropTypes.array,
};

export default BurgerNav;
