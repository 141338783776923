import styled from 'styled-components';
import {normalizeList} from '../../../styles/utils';
import {
    color,
    font,
    navbarHeight,
    navbarHeightMobile,
} from '../../../styles/variables';
import {xlMax, xl} from '../../../styles/media';

export const Navigation = styled.ul`
    ${normalizeList()};

    ${xl`
        display: flex;
        flex-wrap: wrap;
    `}
`;

export const Item = styled.li`
    position: relative;
    margin: 1rem 0;
    display: block;

    ${xl`
        height: ${navbarHeight};
        display: flex;
        align-items: center;

        & + & {
            padding-left: 0.5rem;
        }
    `}

    .ap-link {
        height: ${navbarHeight};
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${color.darkgrey};
        text-decoration: none;

        ${xl`
            height: auto;
            justify-content: flex-start;
        `}

        .ap-text {
            white-space: nowrap;
        }

        .ap-icon {
            margin-right: 0.5em;

            svg {
                fill: ${color.darkgrey};
            }
        }

        &:not(:hover) .ap-text {
            background-image: none;
        }

        &:hover {
            svg {
                fill: ${color.red};
            }

            .ap-text {
                font-weight: ${font.weight.bold};
                color: ${color.red};
            }
        }

        &:focus,
        &:active,
        &.active {
            color: ${color.red};
            font-weight: ${font.weight.bold};

            .ap-icon {
                svg {
                    fill: ${color.red};
                }
            }

            .ap-text {
                background-image: linear-gradient(${color.fog}, ${color.fog});
                background-size: 100% 2px;
            }

            ${xlMax`
                background-color: ${color.red};
                color: ${color.white};

                &:focus {
                    background-color: ${color.petrol};
                }

                .ap-icon {
                    svg {
                        fill: ${color.white};
                    }
                }

                + ul {
                    li {
                        .ap-text {
                            color: ${color.darkgrey};
                        }

                        .active {
                            .ap-text {
                                color: ${color.white};
                            }
                        }
                    }
                }

                &:hover {
                    .ap-text {
                        color: ${color.white};
                    }

                    .ap-icon {
                        svg {
                            fill: ${color.white};
                        }
                    }
                }
            `}
        }
    }
`;

export const DropDown = styled.ul`
    ${normalizeList()};
    ${(props) => (props.open ? `display: block;` : `display: none;`)};
    background-color: ${color.lightBlue};
    margin-top: 0;

    ${xl`
        position: absolute;
        min-width: 15rem;
        max-width: 30rem;
        top: 100%;
        right: 0;
        background-color: ${color.white};
        box-shadow: 0 2px 4px 0 rgba(122, 169, 189, 0.25);
    `}
`;

export const DropDownItem = styled.li`
    display: block;

    .ap-link {
        width: 100%;
        height: ${navbarHeightMobile};
        padding: 1rem;

        &:hover {
            background-color: ${color.lightPetrol};
        }

        &.active,
        &:active {
            background-color: ${color.red};

            .ap-text {
                color: ${color.white};
            }

            &:hover {
                .ap-text {
                    color: ${color.white};
                }

                .ap-icon {
                    svg {
                        fill: ${color.white};
                    }
                }
            }
        }

        &:focus {
            background-color: ${color.petrol};

            .ap-text {
                color: ${color.white};
            }
        }
`;
