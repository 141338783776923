import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';

import Label from './Label';
import {StyledInput} from './Input.styles';
import ErrorMessage from './ErrorMessage';
import {sectionThemeNames} from '../../layout/Grid/Section.styles';

export const Input = forwardRef((props, ref) => {
    const {
        name,
        label,
        theme,
        value,
        isDisabled,
        placeholder,
        onChange,
        errors,
        ...rest
    } = props;
    const error = errors && !!errors[name];
    return (
        <>
            {label && (
                <Label
                    htmlFor={name}
                    label={label}
                    error={error}
                    disabled={isDisabled}
                />
            )}
            <StyledInput
                {...rest}
                ref={ref}
                aria-label={name}
                id={name}
                name={name}
                disabled={isDisabled}
                placeholder={placeholder}
                theme={theme}
                value={value}
                onChange={onChange}
                error={error}
            />
            {error && <ErrorMessage>{errors[name].message}</ErrorMessage>}
        </>
    );
});

Input.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    theme: PropTypes.oneOf(sectionThemeNames),
    value: PropTypes.string,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    errors: PropTypes.object.isRequired,
};

Input.defaultProps = {
    theme: 'default',
    value: '',
    placeholder: '',
};

const ControlledInput = ({control, ...props}) => {
    return <Controller as={Input} control={control} {...props} />;
};

ControlledInput.propTypes = {
    control: PropTypes.object.isRequired,
};

export default ControlledInput;
