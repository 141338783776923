import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {
    Overlay,
    ModalContainer,
    CloseButton,
    ModalWrapper,
    StyledIcon,
} from './styles';

const Lightbox = ({
    children,
    isOpen,
    showPadding,
    onClose,
    maxWidth,
    noCloseButton,
}) => {
    const [opacity, setOpacity] = useState(0);
    return (
        <Overlay
            opacity={opacity}
            ariaHideApp={false}
            onAfterOpen={() => setOpacity(1)}
            onAfterClose={() => setOpacity(0)}
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={maxWidth}>
            <ModalContainer showPadding={showPadding}>
                {noCloseButton ? null : (
                    <CloseButton onClick={onClose}>
                        <StyledIcon name="close" size="2rem" />
                    </CloseButton>
                )}
                <ModalWrapper>{children}</ModalWrapper>
            </ModalContainer>
        </Overlay>
    );
};

Lightbox.propTypes = {
    children: PropTypes.any,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    showPadding: PropTypes.bool,
    maxWidth: PropTypes.string,
    noCloseButton: PropTypes.bool,
};

Lightbox.defaultProps = {
    children: null,
    isOpen: false,
    maxWidth: '25rem',
};

export default Lightbox;
