import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';

import {Checkbox} from './Checkbox';
import {Wrapper} from './CheckboxGroup.styles';
import ErrorMessage from './ErrorMessage';

export const CheckboxGroup = forwardRef((props, ref) => {
    const {
        name,
        imgCheckbox,
        value,
        isDisabled,
        onChange,
        children,
        errors,
    } = props;
    const error = errors && !!errors[name];
    return (
        <>
            <Wrapper imgCheckbox={imgCheckbox}>
                {children.map((child) => (
                    <Checkbox
                        {...child.props}
                        ref={ref}
                        key={`${name}-${child.props.name}`}
                        id={`${name}-${child.props.name}`}
                        name={child.props.name}
                        value={value[child.props.name]}
                        disabled={isDisabled}
                        onChange={(event, checkboxName) =>
                            onChange({
                                ...value,
                                [checkboxName]: event.target.checked,
                            })
                        }
                        error={error}
                    />
                ))}
            </Wrapper>
            {error && <ErrorMessage>{errors[name].message}</ErrorMessage>}
        </>
    );
});

CheckboxGroup.propTypes = {
    name: PropTypes.string.isRequired,
    imgCheckbox: PropTypes.bool,
    value: PropTypes.object,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    errors: PropTypes.object.isRequired,
    children: PropTypes.any, // TODO: custom validator, allow only Checkboxes
};

const ControlledCheckboxGroup = ({control, ...props}) => {
    const {name} = props;
    return (
        <Controller
            name={name}
            control={control}
            render={({value, onChange}) => (
                <CheckboxGroup
                    {...props}
                    value={value}
                    onChange={(newValue) => onChange(newValue)}
                />
            )}
        />
    );
};

ControlledCheckboxGroup.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
};

export default ControlledCheckboxGroup;
