import React from 'react';
import {Navigation, Item, List, Category} from './SitemapNav.styles';
import Column from '../Grid/Column';
import Container from '../Grid/Container';
import Row from '../Grid/Row';
import {linkValidation} from '../../../utils/validator-utils';
import PropTypes from 'prop-types';

const SitemapNav = ({items}) => {
    if (!items) {
        return null;
    }
    const categoryItems = items.map((categoryItem, index) => {
        const navItems = categoryItem.items.map((navItem, index) => (
            <Item key={index}>{navItem}</Item>
        ));
        return (
            <Column md={6} lg={4} key={index}>
                <Category>{categoryItem.category}</Category>
                <List>{navItems}</List>
            </Column>
        );
    });

    return (
        <Navigation className="ap-nav ap-nav--meta">
            <Container noGutter>
                <Row>{categoryItems}</Row>
            </Container>
        </Navigation>
    );
};

SitemapNav.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            category: PropTypes.string,
            items: linkValidation(),
        })
    ),
};

export default SitemapNav;
