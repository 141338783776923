import {css, createGlobalStyle} from 'styled-components';
import {normalize} from 'polished';

import fonts from './fonts';
import {color, font, outline, navbarHeight, border} from './variables';
import {md, mdMax} from './media';

const fontSizeBase = css`
    font-size: ${font.size.base.mobile};

    ${md`
        font-size: ${font.size.base.desktop};
    `};
`;

export const fontStyleBase = css`
    color: ${font.color.text};
    font-family: ${font.type.sansSerif};
    font-weight: ${font.weight.regular};
    font-style: normal;
    line-height: 1.5;
    overflow-wrap: break-word;
    word-break: break-word;
`;

export const globalListStyle = css`
    font-family: ${font.type.sansSerif};
    font-weight: ${font.weight.regular};
    font-size: ${font.size.small};
    color: ${font.color.text};
    line-height: 1.5;
    margin: 0.5rem 0 1.5rem;
    padding-left: 0;
    list-style: none;

    li {
        position: relative;
        display: block;
        padding-left: 1.3rem;
        margin-bottom: 0.5rem;

        &::before {
            position: absolute;
            left: 0;
        }

        p {
            display: inline;
        }
    }
`;

export const globalUnOrderedListStyle = css`
    li::before {
        content: '';
        border-radius: 50%;
        width: 5px;
        height: 5px;
        top: 0.6rem;
        background-color: ${color.red};
    }
`;

export const globalOrderedListStyle = css`
    li {
        &::before {
            font-family: ${font.type.serif};
            color: ${color.red};
        }
    }
`;

export default createGlobalStyle`
    ${normalize()}
    ${fonts}

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    html,
    body {
        margin: 0;
        padding: 0;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        ${fontStyleBase}
        ${fontSizeBase}
    }

    body {
        padding-top: ${navbarHeight};
    }

    input,
    textarea,
    button {
        ${fontStyleBase}
    }

    svg {
        max-width: 100%;
        width: auto;
        height: auto;
    }

    table {
        table-layout: fixed;
        border-collapse: collapse;
        border-spacing: 0;
        margin: 0 0 1rem 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;

        th, td {
            border-bottom: ${border.grey};
            text-align: left;
            padding: .5rem 0.75rem;
            overflow: hidden;
        }

        tr:last-child {
            td {
                border-bottom: none;
            }
        }

        p {
            margin: 0!important;
        }
    }

    .ap-rte {
        ol {
            ${globalListStyle}
            ${globalOrderedListStyle}
        }

        ul {
            ${globalListStyle}
            ${globalUnOrderedListStyle}
        }

        a,
        a:visited {
            display: inline;
            align-items: center;
            font-size: ${font.size.small};
            color: ${font.color.link};
            text-decoration: none;

            background-image: linear-gradient(
            ${font.color.link},
            ${font.color.link}
            );
            background-position: 0 100%;
            background-repeat: no-repeat;
            background-size: 100% 2px;
            transition: background-size 0.3s;
        }

        a:hover,
        a:active,
        a:focus {
            text-decoration: none;
            background-size: 0 2px;
        }

        a:focus {
            outline: ${outline.focus};
            outline-offset: 4px;
        }
    }

    b, strong {
        font-weight: ${font.weight.bold};
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    #cookiebanner {
        ${mdMax`
            height: 100%;
            overflow:auto;
        `};
        /* APTQ-800: make both buttons equally wide */
        a.c-button {
            width: 300px !important;
        }
    }

    .CookieDeclaration {
        a {
            display: inline;
            align-items: center;
            font-size: ${font.size.small};
            color: ${font.color.link};
            text-decoration: none;
            cursor: pointer;
            background-image: linear-gradient(
                ${font.color.link},
                ${font.color.link}
            );
            background-position: 0 100%;
            background-repeat: no-repeat;
            background-size: 100% 2px;
            transition: background-size 0.3s;

            &:hover,
            &:active,
            &:focus {
                background-size: 0 2px;
            }

            &:focus {
                outline: ${outline.focus};
                outline-offset: 4px;
            }
        }
    }

    .cookie-container {
        .button-container {
            ${mdMax`
                flex-direction: column;
                text-align: left;
            `};
        }
    }

    .text-center {
        text-align: center;
    }

    .d-flex {
        display: flex;
        /* IE11 Fix */
        max-width: 100%;
    }

    .justify-content-center {
        justify-content: center;
    }

    .align-items-center {
        align-items: center;
    }

     .ap-button + .ap-button {
         margin-left: 1rem;
     }
     .ap-button.fullWidth {
        flex: auto;
     }
`;
