import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {Container, Row, Column, Section} from '../../../../layout/Grid';
import Headline from '../../../../components/Headline';
import Paragraph from '../../../../components/Paragraph';
import Button from '../../../../components/Button';
import {ReactComponent as StarsSvg} from '../../../../../assets/images/trainings/img-overlay--punkte.svg';
import lang from '../../../../../lang/pages/aktionsteilnahmen.lang';
import {
    selectLatestPendingReceipt,
    dismissPendingNotification,
} from '../../../../../model/points-receipt';

export const SubmissionEvaluationModal = ({
    theme,
    isRejected,
    points,
    message,
    campaignTitle,
    rejectReason,
    onClick,
}) => {
    const submissionLang = isRejected
        ? lang.userSubmissionDeniedlightbox
        : lang.userSubmissionApprovedlightbox;

    return (
        <Section theme={theme} noPadding>
            <Container width="narrow" className="text-center">
                <Row>
                    <Column xs={12}>
                        <StarsSvg />
                        <Headline fontWeight="bold" type="h5">
                            {submissionLang.headline(points)}
                        </Headline>
                        <Paragraph size="small">
                            {submissionLang.mainParagraph(campaignTitle)}
                        </Paragraph>
                        {isRejected && rejectReason && (
                            <Paragraph size="small">
                                {submissionLang.reasonParagraph(
                                    lang.rejectForm.options.find(
                                        (obj) => obj.value === rejectReason
                                    ).label
                                )}
                            </Paragraph>
                        )}
                        {message && (
                            <Paragraph size="small">
                                {submissionLang.messageParagraph(message)}
                            </Paragraph>
                        )}
                        <Button
                            label={submissionLang.button}
                            onClick={onClick}
                        />
                    </Column>
                </Row>
            </Container>
        </Section>
    );
};

SubmissionEvaluationModal.propTypes = {
    theme: PropTypes.string,
    isRejected: PropTypes.bool,
    points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    campaignTitle: PropTypes.string,
    message: PropTypes.string,
    rejectReason: PropTypes.string,
    onClick: PropTypes.func,
};

SubmissionEvaluationModal.defaultProps = {
    theme: 'default',
    isRejected: false,
    points: 0,
    message: '',
    rejectReason: '',
    campaignTitle: '',
    onClick: () => {},
};

const ConnectedSubmissionEvaluationModal = (props) => {
    const dispatch = useDispatch();
    const latestPendingReceipt = useSelector(selectLatestPendingReceipt);
    if (!latestPendingReceipt.type.startsWith('campaign-submission')) {
        return null;
    }
    const {data} = latestPendingReceipt;
    return (
        <SubmissionEvaluationModal
            {...props}
            onClick={() =>
                dispatch(dismissPendingNotification(latestPendingReceipt.id))
            }
            isRejected={
                latestPendingReceipt.type === 'campaign-submission-rejected'
            }
            points={data && data.points}
            campaignTitle={data && data.campaign_title}
            message={data && data.message}
            rejectReason={data && data.reason}
        />
    );
};

export default ConnectedSubmissionEvaluationModal;
