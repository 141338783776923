import styled, {css} from 'styled-components';

export const imageWrapperStyles = css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export const iconWrapperStyles = css`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    ${(props) => props.imgRadio && imageWrapperStyles};
    ${(props) => props.iconRadio && iconWrapperStyles};

    + .ap-error {
        justify-content: center;
    }
`;
