import React from 'react';
import PropTypes from 'prop-types';
import {
    Section as SectionStyled,
    sectionThemeNames,
    sectionThemes,
} from './Section.styles';

const Section = ({
    children,
    theme,
    yPadding,
    noPadding,
    padding,
    anchor,
    bgImage,
    fullHeader,
    noLineHeight,
}) => {
    const sectionProps = {
        theme: sectionThemes[theme],
        yPadding,
        noPadding,
        padding,
        fullHeader,
        bgImage,
        noLineHeight,
    };
    return (
        <SectionStyled
            id={anchor}
            data-scroll-anchor={anchor}
            {...sectionProps}>
            {children}
        </SectionStyled>
    );
};

Section.propTypes = {
    children: PropTypes.any,
    theme: PropTypes.oneOf(sectionThemeNames),
    yPadding: PropTypes.bool,
    noPadding: PropTypes.bool,
    padding: PropTypes.string,
    fullHeader: PropTypes.bool,
    noLineHeight: PropTypes.bool,
    bgImage: PropTypes.any,
    anchor: PropTypes.string,
};

Section.defaultProps = {
    theme: 'default',
    yPadding: false,
    noPadding: false,
    fullHeader: false,
    bgImage: null,
};

export default Section;
