export default {
    ueberApotique: 'Über Apotique',
    unserAnspruch: 'Unser Anspruch',
    support: 'Support',
    profile: 'Deine Daten',
    deineApotheken: 'Deine Apotheken',
    aktionsteilnahmen: 'Aktion',
    aktionenarchiv: 'Aktionenarchiv',
    punktevergabe: 'Bonuspunkte',
    loginRegistrieren: 'Login / Registrieren',
    logout: 'Ausloggen',
};
