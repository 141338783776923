import React from 'react';
import PropTypes from 'prop-types';
import {BurgerBox, BurgerInner, BurgerStyled} from './styles';

const Burger = ({isOpen, onClick}) => {
    return (
        <BurgerStyled isOpen={isOpen} onClick={onClick}>
            <BurgerBox>
                <BurgerInner isOpen={isOpen} />
            </BurgerBox>
        </BurgerStyled>
    );
};

Burger.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Burger;
