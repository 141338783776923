import React from 'react';
import PropTypes from 'prop-types';

import {StyledButton, Text, Circle} from './styles';
import Icon, {icons} from '../Icon';

const Button = ({
    label,
    secondary,
    ghost,
    icon,
    iconSize,
    iconRight,
    disabled,
    circle,
    onClick,
    className,
    maxContent,
    rightIcon,
}) => {
    if (circle) {
        return (
            <Circle disabled={disabled} onClick={onClick}>
                <Icon name={icon} size={iconSize ? iconSize : '2rem'} />
            </Circle>
        );
    }
    return (
        <StyledButton
            className={className}
            secondary={secondary}
            ghost={ghost}
            disabled={disabled}
            iconRight={iconRight}
            maxContent={maxContent}
            onClick={onClick}>
            {icon && <Icon name={icon} size={iconSize ? iconSize : '1rem'} />}
            <Text>{label}</Text>
            {rightIcon && (
                <Icon
                    name={rightIcon}
                    className="ap-icon-right"
                    size={iconSize ? iconSize : '1rem'}
                />
            )}
        </StyledButton>
    );
};

Button.propTypes = {
    label: PropTypes.string,
    secondary: PropTypes.bool,
    ghost: PropTypes.bool,
    icon: PropTypes.oneOf(Object.keys(icons)),
    rightIcon: PropTypes.oneOf(Object.keys(icons)),
    iconRight: PropTypes.bool,
    disabled: PropTypes.bool,
    circle: PropTypes.bool,
    maxContent: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    iconSize: PropTypes.number,
};

Button.defaultProps = {
    label: '',
    secondary: false,
    ghost: false,
    disabled: false,
    iconRight: false,
    circle: false,
    maxContent: false,
    className: 'ap-button',
};

export default Button;
