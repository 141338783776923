import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {parse as parseQueryString} from 'qs';

import Form, {Input} from '../../../components/Form';
import {Column, Container, Row, Section} from '../../../layout/Grid';
import Paragraph from '../../../components/Paragraph';
import Divider from '../../../components/Divider';
import {color} from '../../../../styles/variables';
import Button from '../../../components/Button';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import {
    identifierValidatorForLogin,
    passwordValidator,
} from '../../../../utils/form-validation';
import {login} from '../../../../model/authentication';
import formLang from '../../../../lang/forms.lang';
import loginLang from '../../../../lang/pages/anmelden.lang';
import Headline from '../../../components/Headline';

import {selectLocation} from '../../../../model/navigation';
import {setRedirectUrl} from '../../../../model/registration';

const validationSchema = yup.object().shape({
    loginIdentifier: identifierValidatorForLogin,
    loginPassword: passwordValidator(true),
});

const defaultValues = {
    loginIdentifier: '',
    loginPassword: '',
};

export const LoginForm = ({onSubmit, theme, inLightbox}) => {
    const location = useSelector(selectLocation);
    const {redirect} = parseQueryString(location?.search, {
        ignoreQueryPrefix: true,
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (redirect) {
            dispatch(setRedirectUrl(redirect));
        }
    }, [dispatch, redirect]);

    const {control, handleSubmit, errors} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });
    return (
        <Section theme={theme} noPadding={inLightbox}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Container width="narrow">
                    <Row>
                        <Column xs={12}>
                            {inLightbox ? (
                                <Headline type="h5">
                                    {loginLang.headline}
                                </Headline>
                            ) : (
                                <Headline type="h1" align="center">
                                    {loginLang.headline}
                                </Headline>
                            )}
                        </Column>
                        <Column md={inLightbox ? 12 : 6}>
                            <Input
                                name={formLang.login.identifier.name}
                                placeholder={
                                    formLang.login.identifier.placeholder
                                }
                                label={formLang.login.identifier.label}
                                theme={theme}
                                control={control}
                                errors={errors}
                            />
                        </Column>
                        <Column md={inLightbox ? 12 : 6}>
                            <Input
                                name={formLang.login.password.name}
                                placeholder={
                                    formLang.login.password.placeholder
                                }
                                type="password"
                                label={formLang.login.password.label}
                                theme={theme}
                                control={control}
                                errors={errors}
                            />
                        </Column>
                        <Column xs={12} className="ap-padding--top">
                            <Button
                                name="submit"
                                type="submit"
                                label={loginLang.submitButton}
                            />
                        </Column>
                        <Column xs={12}>
                            <Paragraph align="center" margin="0">
                                {loginLang.resetPasswordLink(dispatch)}
                            </Paragraph>
                        </Column>
                        <Column xs={12}>
                            <Divider color={color.white} />
                        </Column>
                        <Column xs={12}>
                            <Paragraph size="small" align="center">
                                {loginLang.noAccountYet(dispatch)}
                            </Paragraph>
                        </Column>
                    </Row>
                </Container>
            </Form>
        </Section>
    );
};

LoginForm.propTypes = {
    onSubmit: PropTypes.func,
    theme: PropTypes.oneOf(sectionThemeNames),
    inLightbox: PropTypes.bool,
};

LoginForm.defaultProps = {
    onSubmit: () => {},
    theme: 'default',
    inLightbox: false,
};

const ConnectedLoginForm = (props) => {
    const dispatch = useDispatch();
    const handleSubmit = ({loginIdentifier, loginPassword}) => {
        dispatch(login(loginIdentifier, loginPassword));
    };
    return <LoginForm {...props} onSubmit={handleSubmit} />;
};

export default ConnectedLoginForm;
