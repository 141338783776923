import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper, Container, Text} from './StickyPoints.styles';
import Icon from '../../components/Icon';
import {color} from '../../../styles/variables';

const StickyPoints = ({points}) => {
    return (
        <Wrapper>
            <Container>
                <Icon name="starOutline" size="1.3rem" color={color.white} />
                <Text>{points.toLocaleString()} Pkt.</Text>
            </Container>
        </Wrapper>
    );
};

StickyPoints.propTypes = {
    points: PropTypes.number,
};

StickyPoints.defaultProps = {
    points: 0,
};

export default StickyPoints;
