import styled from 'styled-components';
import {color} from '../../../styles/variables';

export const Wrapper = styled.footer`
    width: 100%;
    border-top: 2px solid ${color.lightBlue};
`;

export const Text = styled.div`
    margin-bottom: 1em;
`;

export const SmallText = styled.div`
    font-size: 0.9em;
`;
