import styled, {css} from 'styled-components';

import {color} from '../../../styles/variables';

export const StyledDivider = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${(props) =>
        props.theme === 'blue'
            ? color.white
            : props.color
            ? props.color
            : color.lightBlue};
    margin-top: 1rem;
    margin-bottom: 1rem;

    ${(props) =>
        props.dashed &&
        css`
            border-bottom: 2px dashed
                ${(props) =>
                    props.theme === 'blue'
                        ? color.white
                        : props.color
                        ? props.color
                        : color.lightBlue};
            height: 0;
            background-color: unset;
        `}
`;
