import styled from 'styled-components';

import {smMax, md} from '../../../styles/media';

export const StyledForm = styled.form`
    .ap-button {
        display: flex;
        margin: 0 auto;

        ${smMax`
		    width: 100%;
	    `};
    }

    .ap-button--inline {
        white-space: nowrap;

        ${md`
            display: inline-flex;
            margin: 0;
        `};
    }

    .ap-button--left {
        ${md`
            margin: 0;
            justify-content: flex-start;
        `};
    }

    .ap-link--login {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ap-link--block {
        display: block;

        ${md`
            display: inline;
        `};
    }

    .ap-col {
        padding-top: 0;
    }

    .ap-padding--top {
        padding-top: 1rem;
    }
`;
