import React from 'react';
import PropTypes from 'prop-types';

import {Row as RowStyled} from './Row.styles';

const Row = ({children, style}) => {
    const rowProps = {
        style,
    };

    return (
        <RowStyled className="ap-row" {...rowProps}>
            {children}
        </RowStyled>
    );
};

Row.propTypes = {
    children: PropTypes.any,
    style: PropTypes.object,
};

export default Row;
