import React from 'react';
import Link from '../../components/Link';
import {routes} from '../../../model/navigation';
import lang from './SitemapNav.lang';

export const SitemapNavItems = [
    {
        category: 'Über Apotique',
        items: [<Link href={routes.anspruch}>{lang.anspruch}</Link>],
    },
    {
        category: 'Prämienshop',
        items: [<Link href={routes.praemien}>{lang.praemien}</Link>],
    },
    {
        category: 'Legal',
        items: [
            <Link href={routes.datenschutz}>{lang.datenschutz}</Link>,
            <Link href={routes.impressum}>{lang.impressum}</Link>,
            <Link href={routes.support}>{lang.support}</Link>,
            <Link href={routes.nutzungsbedingungen}>
                {lang.nutzungsbedingungen}
            </Link>,
            <Link href={routes.pflichttexte}>{lang.pflichttexte}</Link>,
        ],
    },
];

export const StorySitemapNavItems = [
    {
        category: 'Über Apotique',
        items: [<Link href={routes.anspruch}>{lang.anspruch}</Link>],
    },
    {
        category: 'Prämienshop',
        items: [<Link href={routes.praemien}>{lang.praemien}</Link>],
    },
    {
        category: 'Legal',
        items: [
            <Link href={routes.datenschutz}>{lang.datenschutz}</Link>,
            <Link href={routes.impressum}>{lang.impressum}</Link>,
            <Link href={routes.support}>{lang.support}</Link>,
            <Link href={routes.nutzungsbedingungen}>
                {lang.nutzungsbedingungen}
            </Link>,
            <Link href={routes.pflichttexte}>{lang.pflichttexte}</Link>,
        ],
    },
];
