import styled from 'styled-components';
import {color, font, navbarHeight} from '../../../styles/variables';
import {xl} from '../../../styles/media';

export const Wrapper = styled.div`
    position: absolute;
    top: ${navbarHeight};
    right: 1rem;
    display: inline-block;
    padding: 0.37rem 0.8rem;
    background-color: ${color.darkBlue};
    color: ${color.white};
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    ${xl`
        right: 4.5rem;
    `}
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Text = styled.span`
    margin-left: 0.55rem;
    color: ${color.white};
    font-size: ${font.size.small};
`;
