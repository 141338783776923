import React from 'react';
import Link from '../ui/components/Link';
import {routes} from '../model/navigation/routes';

export default {
    login: {
        identifier: {
            name: 'loginIdentifier', // must be distinct from other username fields
            placeholder: 'Username oder E-Mail-Adresse',
            label: 'Username oder E-Mail-Adresse',
        },
        password: {
            name: 'loginPassword', // must be distinct from other password fields
            placeholder: 'Passwort',
            label: 'Passwort',
        },
    },
    salutation: {
        name: 'salutation',
        label: 'Anrede',
        options: [
            {value: 'Frau', label: 'Frau'},
            {value: 'Herr', label: 'Herr'},
            {value: 'Keine Angabe', label: 'keine Angabe'},
        ],
    },
    username: {
        name: 'username',
        placeholder: 'Benutzername',
        label: 'Benutzername',
        tooltip: {
            text:
                'Dein Benutzername muss mit einem Buchstaben beginnen, zwischen 3 und 20 Zeichen lang sein und darf bis auf . und _ keine Leer- oder Sonderzeichen enthalten.',
        },
    },
    firstName: {
        name: 'firstName',
        placeholder: 'Vorname',
        label: 'Vorname',
    },
    lastName: {
        name: 'lastName',
        placeholder: 'Nachname',
        label: 'Nachname',
    },
    email: {
        name: 'email',
        placeholder: 'E-Mail-Adresse',
        label: 'E-Mail-Adresse',
    },
    referralCode: {
        name: 'referralCode',
        placeholder: 'Einladungscode Deines Kollegen',
        label: 'Einladungscode',
        tooltip: {
            text: (
                <>
                    Zur Zeit findet keine Aktion mit Einladungscode statt. Du
                    kannst Dich aber auch gerne ohne Einladungscode
                    registrieren.
                </>
            ),
        },
    },
    zip: {
        name: 'zip',
        placeholder: 'PLZ Deiner Apotheke',
        label: 'PLZ der Apotheke',
    },
    pharmacy: {
        name: 'pharmacy',
        label1: 'Meine Apotheke',
        label2: 'Apotheke',
        loadPharmacies: 'Lade Apotheken...',
        noPharmacies: 'Keine Apotheken gefunden',
    },
    idNumber: {
        name: 'identificationNumber',
        placeholder: 'Kundennummer oder BGA Nummer',
        label: 'Kundennummer bei Pohl-Boskamp oder BGA Nummer',
        tooltip: {
            text: (
                <>
                    Deine Apotheke sollte eine BGA-Nummer und eine
                    Pohl-Boskamp-Kundennummer haben. Die Kundennummer findest Du
                    z.B. auf allen Rechnungen von Pohl-Boskamp oder in den
                    Pohl-Boskamp-Kontaktdaten in Eurem Computersystem. Falls Du
                    die Nummer nicht herausfinden kannst, melde Dich bitte bei
                    uns über das Kontaktformular.
                </>
            ),
        },
    },
    password: {
        name: 'password',
        placeholder: 'Passwort',
        label: 'Passwort',
        tooltip: {
            text: 'Dein Passwort muss mindestens 6 Zeichen enthalten.',
        },
    },
    choosePassword: {
        name: 'password',
        placeholder: 'Passwort wählen',
        label: 'Passwort wählen',
    },
    passwordConfirm: {
        name: 'passwordConfirmation',
        placeholder: 'Passwort bestätigen',
        label: 'Passwort bestätigen',
    },
    termsOfUseAndPrivacyPolicy: {
        name: 'termsOfUseAndPrivacyPolicy',
        label: (
            <>
                Ich habe die{' '}
                <Link href={routes.nutzungsbedingungen}>
                    Nutzungsbedinungen
                </Link>{' '}
                und{' '}
                <Link href={routes.datenschutz}>Datenschutzbestimmungen</Link>{' '}
                gelesen und stimme diesen zu.
            </>
        ),
    },
    sharePoints: {
        name: 'sharePoints',
        label:
            'Ich willige ein, dass Arbeitskollegen aus meiner Apotheke meinen Punktestand einsehen können. (Wir möchten Deinen Erfolg mit Deinen angemeldeten Arbeitskollegen teilen, damit ihr gemeinsam die Rangliste erklimmen könnt!)',
    },
    newsletter: {
        name: 'newsletter',
        label:
            'Ich möchte per E-Mail informiert werden, sobald ein neues Training in der Apotique zur Verfügung steht.',
    },
    message: {
        name: 'message',
        placeholder: 'Wie können wir Dir helfen?',
        label: 'Deine Nachricht',
    },
    reasonPointsAssignment: {
        name: 'reason',
        label: 'Anlass der Punktevergabe',
        placeholder: 'Anlass wählen',
    },
    pharmacyUsers: {
        name: 'pharmacyUsers',
        label: 'Apothekenmitarbeiter/in',
        placeholder: 'Bitte wählen',
        noUser: 'Keine Mitarbeiter/in gefunden',
        multiValueLabel: 'Alle Mitarbeiter',
    },
};
