import styled from 'styled-components';

import {color, font} from '../../../styles/variables';

export const StyledLabel = styled.label`
    display: block;
    margin-bottom: 0.5rem;
    color: ${(props) => (props.error ? color.red : font.color.text)};
    font-weight: ${font.weight.semibold};
    font-size: 1rem;
    line-height: 1.2;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;
