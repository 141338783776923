import React from 'react';
import PropTypes from 'prop-types';

import {ApotiqueLogo} from './styles';

import {ReactComponent as Subtitle} from '../../../assets/logos/subline.svg';
import {ReactComponent as Apotique} from '../../../assets/logos/apotique.svg';
import Link from '../../components/Link';
import {routes} from '../../../model/navigation/routes';

const Logo = ({withSubtitle}) => {
    return (
        <ApotiqueLogo withSubtitle={withSubtitle} className="ap-logo">
            <Link href={routes.start}>
                {withSubtitle ? <Subtitle /> : <Apotique />}
            </Link>
        </ApotiqueLogo>
    );
};

Logo.propTypes = {
    withSubtitle: PropTypes.bool,
};

Logo.defaultProps = {
    withSubtitle: false,
};

export default Logo;
