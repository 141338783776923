import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Navigation, Item, DropDown, DropDownItem} from './MainNav.styles';
import {routes} from '../../../model/navigation';
import {matchLocation} from '../../../utils/route-utils';
import lang from './MetaNav.lang';
import Link from '../../components/Link';
import {useDispatch} from 'react-redux';
import {logout} from '../../../model/authentication';
import {Roles} from '../../../model/profile/';

const MetaNav = ({location, loggedIn, username, userRoles}) => {
    const [dropdown, setDropdown] = useState(null);
    const dispatch = useDispatch();
    const profilDropdown = useRef();
    const aboutApotiqueDropdown = useRef();

    const handleClickOutside = (e) => {
        let node = null;
        switch (dropdown) {
            case routes.anspruch:
                node = aboutApotiqueDropdown;
                break;
            case routes.profil:
                node = profilDropdown;
                break;
            default:
                break;
        }

        if (node && node.current && node.current.contains(e.target)) {
            // inside click
            return;
        }

        // outside click
        closeAllLayers();
    };

    useEffect(() => {
        if (dropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const closeAllLayers = () => {
        setDropdown(null);
    };

    const openProfilDropdown = (e) => {
        e.preventDefault();
        setDropdown(routes.profil);
    };

    return (
        <Navigation className="ap-nav ap-nav--meta">
            <Item key={routes.anspruch}>
                <Link
                    href={routes.anspruch}
                    active={matchLocation(routes.anspruch, location)}
                    icon="info"
                    iconLeft>
                    {lang.ueberApotique}
                </Link>
            </Item>
            <Item key={routes.support}>
                <Link
                    href={routes.support}
                    active={matchLocation(routes.support, location)}
                    icon="email"
                    iconLeft>
                    {lang.support}
                </Link>
            </Item>
            {loggedIn ? (
                <Item key={routes.profil}>
                    <Link
                        onClick={openProfilDropdown}
                        href={routes.profil}
                        active={matchLocation(routes.profil, location)}
                        icon="user"
                        iconLeft>
                        {username || lang.profile}
                    </Link>
                    <DropDown
                        ref={profilDropdown}
                        open={dropdown === routes.profil}>
                        {userRoles.includes(Roles.SALES_REPRESENTATIVE) && (
                            <>
                                <DropDownItem>
                                    <Link
                                        href={routes.apothekentabelle}
                                        active={matchLocation(
                                            routes.apothekentabelle,
                                            location
                                        )}>
                                        {lang.deineApotheken}
                                    </Link>
                                </DropDownItem>
                                <DropDownItem>
                                    <Link
                                        href={routes.aktionsteilnahmen}
                                        active={matchLocation(
                                            routes.aktionsteilnahmen,
                                            location
                                        )}>
                                        {lang.aktionsteilnahmen}
                                    </Link>
                                </DropDownItem>
                                <DropDownItem>
                                    <Link
                                        href={routes.aktionenarchiv}
                                        active={matchLocation(
                                            routes.aktionenarchiv,
                                            location
                                        )}>
                                        {lang.aktionenarchiv}
                                    </Link>
                                </DropDownItem>
                                <DropDownItem>
                                    <Link
                                        href={routes.punktevergabe}
                                        active={matchLocation(
                                            routes.punktevergabe,
                                            location
                                        )}>
                                        {lang.punktevergabe}
                                    </Link>
                                </DropDownItem>
                            </>
                        )}
                        <DropDownItem>
                            <Link
                                href={routes.profil}
                                active={matchLocation(routes.profil, location)}>
                                {lang.profile}
                            </Link>
                        </DropDownItem>
                        <DropDownItem>
                            <Link href="#" onClick={() => dispatch(logout())}>
                                {lang.logout}
                            </Link>
                        </DropDownItem>
                    </DropDown>
                </Item>
            ) : (
                <Item key={routes.anmelden}>
                    <Link
                        href={
                            matchLocation(routes.anmelden, location)
                                ? routes.anmelden
                                : routes.trainings
                        }
                        active={matchLocation(routes.anmelden, location)}
                        icon="login"
                        iconLeft>
                        {lang.loginRegistrieren}
                    </Link>
                </Item>
            )}
        </Navigation>
    );
};

MetaNav.propTypes = {
    location: PropTypes.object,
    loggedIn: PropTypes.bool,
    username: PropTypes.string,
    userRoles: PropTypes.array,
};

MetaNav.defaultProps = {
    userRoles: [],
};

export default MetaNav;
