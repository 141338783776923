import React from 'react';

export default {
    headline: (
        <i>
            Überblick <strong>Deiner Aktionsteilnehmer</strong>
        </i>
    ),
    paragraph:
        'Erfahre in diesem Bereich, wie viele User der beteiligten Apotheken teilgenommen haben. Hier kannst Du sie mit Punkten für ihr persönliches Konto oder ihr Apotheken-Team belohnen – indem Du in der rechten Spalte auswählst, wer Punkte für die Aktionsteilnahme erhalten soll.',
    tableHeadings: [
        <>Ak&shy;tion</>,
        <>Sta&shy;tus</>,
        <>Teil&shy;nehmer</>,
        <>Apo&shy;theke</>,
        <>Bei&shy;trag</>,
        <>Punkte&shy;vergabe</>,
    ],
    submissionImage: {
        thumbnailAlt: 'Icon einer Lupe auf rotem quadratischen Hintergrund',
        lightboxImageAlt: (user) => `Eingereichtes Foto von ${user}`,
    },
    acceptForm: {
        headline: 'Teilnahme annehmen',
        paragraph: (
            <>
                Hier kannst Du Deinem Teilnehmer Punkte für seinen Beitrag geben
                und ihm noch eine Nachricht hinterlassen, wenn Du möchtest.
            </>
        ),
        message: {
            name: 'message',
            placeholder: 'Schreibe eine kurze Nachricht',
            label: 'Nachricht (optional)',
        },
        submitButton: 'Bestätigen',
        cancelLink: 'abbrechen',
    },
    rejectForm: {
        headline: 'Teilnahme ablehnen',
        paragraph:
            'Lass den Teilnehmer wissen, warum sein Beitrag abgelehnt worden ist.',
        select: {
            name: 'reason',
            label: 'Grund für die Ablehnung',
            placeholder: 'Bitte wählen',
        },
        options: [
            {
                value: 'invalid_image',
                label: 'Das Bild entspricht nicht den Teilnahmebedingungen.',
            },
            {
                value: 'deviating_product',
                label: 'Es wurde ein anderes Präparat abgebildet.',
            },
            {
                value: 'invalid',
                label: 'Das Bild entspricht nicht den Teilnahmebedingungen.',
            },
        ],
        message: {
            name: 'message',
            placeholder: 'Schreibe eine kurze Erklärung',
            label: 'Nachricht (optional)',
        },
        submitButton: 'Bestätigen',
        cancelLink: 'abbrechen',
    },
    userSubmissionDeniedlightbox: {
        headline: () => `Dein Beitrag konnte leider nicht gewertet werden`,
        mainParagraph: (campaignName) => (
            <>
                Dein Beitrag zur Aktion "{campaignName}" konnte leider nicht
                gewertet werden. Doch keine Sorge: Du kannst es gerne noch
                einmal versuchen. Wende Dich bei Fragen einfach an Deinen
                Außendienst.
            </>
        ),
        reasonParagraph: (reason) => (
            <>
                <strong>Begründung</strong>:
                <br />
                {reason}
            </>
        ),
        messageParagraph: (message) => (
            <>
                <strong>Nachricht</strong>:
                <br />
                {message}
            </>
        ),
        button: 'OK',
    },
    userSubmissionApprovedlightbox: {
        headline: (points) => {
            return `${points} Punkte erhalten`;
        },
        mainParagraph: (campaignName) => (
            <>
                Herzlichen Glückwunsch, Du hast für die Teilnahme an der Aktion
                "{campaignName}" neue Punkte erhalten. In der Punktehistorie
                kannst Du einsehen, für welche Aktionen Du bereits Punkte
                erhalten hast.
            </>
        ),

        messageParagraph: (message) => (
            <>
                <strong>Nachricht</strong>:
                <br />
                {message}
            </>
        ),
        button: 'OK',
    },
};
