import styled from 'styled-components';

import {normalizeList} from '../../../styles/utils';
import {color, font} from '../../../styles/variables';

export const Navigation = styled.div``;

export const List = styled.ul`
    ${normalizeList()};
    margin-bottom: 1.8rem;
    line-height: 1.5;
`;

export const Item = styled.li`
    margin-bottom: 1rem;

    .ap-link {
        display: block;
        text-decoration: none;
        color: ${color.grey};

        &:active,
        &:focus,
        &:hover {
            color: ${color.red};
        }

        .ap-text {
            font-weight: ${font.weight.regular};

            &,
            &:visited,
            &:active,
            &:focus,
            &:hover {
                background: none;
            }
        }
    }
`;

export const Category = styled.div`
    font-weight: ${font.weight.bold};
    font-family: ${font.type.serif};
    margin-bottom: 1.2rem;
`;
