import '../../utils/polyfills';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from 'react-helmet';

import Footer from './Footer';
import GlobalStyle from '../../styles/global';
import Navbar from './Navbar';
import {Main} from './Layout.styles';
import {SitemapNavItems} from './Navigation/SitemapNav.data';
import {selectIsLoggedIn} from '../../model/authentication';
import BackToTop from '../components/Button/BackToTop';
import {scrollToTop} from '../../utils/scroll-utils';
import Lightbox from '../components/Lightbox';
import {selectUser, selectPersonalData} from '../../model/profile';
import {
    selectIsOpen as selectIsLoginLightboxOpen,
    close as closeLoginLightbox,
} from '../../model/login-lightbox';
import {
    selectIsOpen as selectIsInterimLightboxOpen,
    close as closeInterimLightbox,
} from '../../model/interim-lightbox';
import {
    selectReferralCodeStatus,
    setReferralCodePassive,
} from '../../model/registration';

import {
    selectIsOpen as selectIsPointLimitLightboxOpen,
    close as closePointLimitLightbox,
} from '../../model/point-limit-lightbox';
import LoginForm from '../domains/authentication/LoginForm';
import {selectRoles} from '../../model/profile';
import InterimMessage from '../domains/profile/InterimMessage';
import {
    selectIsPointsReceiptLightboxOpen,
    selectLatestPendingReceipt,
} from '../../model/points-receipt';
import UserFeedbackForm from '../domains/sales-agent-user-interactions/UserFeedbackForm';
import SubmissionEvaluationModal from '../domains/sales-agent-user-interactions/UserCampaignSubmissions/SubmissionEvaluationModal';
import PointLimitMessage from '../domains/point-limit/PointLimitMessage';
import lang from './Layout.lang';
import ReferralCodeMessage from '../domains/profile/ReferralCodeMessage';

const Layout = ({children}) => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const userRoles = useSelector(selectRoles);
    const profile = useSelector(selectUser);
    const isLoginLightboxOpen = useSelector(selectIsLoginLightboxOpen);
    const isInterimLightboxOpen = useSelector(selectIsInterimLightboxOpen);
    const isPointLimitLightboxOpen = useSelector(
        selectIsPointLimitLightboxOpen
    );
    const isPtaLightboxOpen = useSelector(selectReferralCodeStatus);
    const personalData = useSelector(selectPersonalData);

    const isPtaActive = personalData.referralCode !== undefined;

    const isPointsReceiptLightboxOpen = useSelector(
        selectIsPointsReceiptLightboxOpen
    );
    const latestPendingReceipt = useSelector(selectLatestPendingReceipt);
    const [latestKnownScrollY, setLatestKnownScrollY] = useState(
        typeof window !== 'undefined' ? window.pageYOffset : 0
    );
    const [upstairs, setUpstairs] = useState(latestKnownScrollY < 400);

    useEffect(() => {
        const handleScroll = () => {
            setLatestKnownScrollY(window.pageYOffset);
            const isUpstairs = latestKnownScrollY < 400;
            if (upstairs !== isUpstairs) {
                setUpstairs(latestKnownScrollY < 400);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GlobalStyle />
            <Helmet
                title={lang.title}
                meta={[
                    {
                        name: 'description',
                        content: lang.description,
                    },
                    {
                        name: 'keywords',
                        content: lang.keywords.join(', '),
                    },
                ]}
                defer={false}
            />
            <Navbar
                isLoggedIn={isLoggedIn}
                profile={profile}
                userRoles={userRoles}
            />
            <Main>{children}</Main>
            <Footer sitemapNavItems={SitemapNavItems} />
            <BackToTop onClick={scrollToTop} show={!upstairs} />
            <Lightbox
                maxWidth="25rem"
                isOpen={isLoginLightboxOpen}
                onClose={() => dispatch(closeLoginLightbox())}>
                <LoginForm theme="blue" inLightbox />
            </Lightbox>
            <Lightbox
                maxWidth="25rem"
                isOpen={isInterimLightboxOpen}
                onClose={() => dispatch(closeInterimLightbox())}>
                <InterimMessage
                    onButtonClick={() => dispatch(closeInterimLightbox())}
                />
            </Lightbox>
            <Lightbox
                maxWidth="25rem"
                isOpen={isPointsReceiptLightboxOpen}
                noCloseButton>
                {latestPendingReceipt &&
                    latestPendingReceipt.type === 'manual' && (
                        <UserFeedbackForm theme="blue" />
                    )}
                {latestPendingReceipt &&
                    latestPendingReceipt.type.startsWith(
                        'campaign-submission'
                    ) && <SubmissionEvaluationModal theme="blue" />}
            </Lightbox>
            <Lightbox
                maxWidth="25rem"
                isOpen={isPointLimitLightboxOpen}
                onClose={() => dispatch(closePointLimitLightbox())}>
                <PointLimitMessage theme="blue" />
            </Lightbox>
            {isPtaActive && (
                <Lightbox
                    maxWidth="25rem"
                    isOpen={isPtaLightboxOpen}
                    onClose={() => dispatch(setReferralCodePassive())}>
                    <ReferralCodeMessage theme="blue" />
                </Lightbox>
            )}
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.any.isRequired,
};

export default Layout;
