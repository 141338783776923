import React from 'react';
import PropTypes from 'prop-types';

import {StyledParagraph, sizes} from './styles';
import {font} from '../../../styles/variables';

const Paragraph = ({
    children,
    align,
    fontWeight,
    serifFont,
    padding,
    margin,
    size,
    extraLineSpace,
    className,
}) => {
    const paragraphProps = {
        align,
        fontWeight,
        serifFont,
        padding,
        margin,
        size,
        extraLineSpace,
        className,
    };

    return <StyledParagraph {...paragraphProps}>{children}</StyledParagraph>;
};

Paragraph.propTypes = {
    children: PropTypes.any,
    align: PropTypes.string,
    fontWeight: PropTypes.oneOf(Object.keys(font.weight)),
    serifFont: PropTypes.bool,
    padding: PropTypes.string,
    margin: PropTypes.string,
    size: PropTypes.oneOf(sizes),
    extraLineSpace: PropTypes.bool,
    className: PropTypes.string,
};

Paragraph.defaultProps = {
    padding: '0.5rem',
    margin: '0 0 1rem 0',
};

export default Paragraph;
