import React from 'react';
import PropTypes from 'prop-types';

import {H1, H2, H3, H4, H5, H6} from './styles';
import {font} from '../../../styles/variables';

const Headline = ({
    type,
    children,
    hideTopMargin,
    hideBottomMargin,
    align,
    fontWeight,
    flexGrow,
    className,
}) => {
    switch (type) {
        case 'h1':
            return (
                <H1
                    hideTopMargin={hideTopMargin}
                    hideBottomMargin={hideBottomMargin}
                    align={align}
                    fontWeight={fontWeight}
                    flexGrow={flexGrow}
                    className={className}>
                    {children}
                </H1>
            );
        case 'h2':
            return (
                <H2
                    hideTopMargin={hideTopMargin}
                    hideBottomMargin={hideBottomMargin}
                    align={align}
                    fontWeight={fontWeight}
                    flexGrow={flexGrow}
                    className={className}>
                    {children}
                </H2>
            );
        case 'h3':
            return (
                <H3
                    hideTopMargin={hideTopMargin}
                    hideBottomMargin={hideBottomMargin}
                    align={align}
                    fontWeight={fontWeight}
                    flexGrow={flexGrow}
                    className={className}>
                    {children}
                </H3>
            );
        case 'h4':
            return (
                <H4
                    hideTopMargin={hideTopMargin}
                    hideBottomMargin={hideBottomMargin}
                    align={align}
                    fontWeight={fontWeight}
                    flexGrow={flexGrow}
                    className={className}>
                    {children}
                </H4>
            );
        case 'h5':
            return (
                <H5
                    hideTopMargin={hideTopMargin}
                    hideBottomMargin={hideBottomMargin}
                    align={align}
                    fontWeight={fontWeight}
                    flexGrow={flexGrow}
                    className={className}>
                    {children}
                </H5>
            );
        case 'h6':
            return (
                <H6
                    hideTopMargin={hideTopMargin}
                    hideBottomMargin={hideBottomMargin}
                    align={align}
                    fontWeight={fontWeight}
                    flexGrow={flexGrow}
                    className={className}>
                    {children}
                </H6>
            );
        default:
            return null;
    }
};

Headline.propTypes = {
    type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
    children: PropTypes.any.isRequired,
    hideTopMargin: PropTypes.bool,
    hideBottomMargin: PropTypes.bool,
    align: PropTypes.string,
    fontWeight: PropTypes.oneOf(Object.keys(font.weight)),
    flexGrow: PropTypes.number,
    className: PropTypes.string,
};

Headline.defaultProps = {
    fontWeight: 'regular',
};

export default Headline;
