import styled from 'styled-components';

import {StyledButton} from './styles';

export const Button = styled(StyledButton)`
    width: 48px;
    padding: 0;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    transition: opacity 0.2s ease-in-out;
    opacity: ${({show}) => (show ? '1' : '0')};
    pointer-events: ${({show}) => (show ? 'all' : 'none')};

    .ap-icon {
        transform: rotate(-90deg);
        margin: 0;
    }
`;
