import React from 'react';
import PropTypes from 'prop-types';
import {
    Container as ContainerStyled,
    contentMaxWidths,
} from './Container.styles';

const Container = ({children, width, noGutter, doubleGutter, className}) => {
    const containerProps = {
        width,
        noGutter,
        doubleGutter,
    };

    return (
        <ContainerStyled
            {...containerProps}
            className={`ap-container ${className}`}>
            {children}
        </ContainerStyled>
    );
};

Container.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    width: PropTypes.oneOf(contentMaxWidths),
    noGutter: PropTypes.bool,
    doubleGutter: PropTypes.bool,
};

Container.defaultProps = {
    noGutter: false,
    doubleGutter: false,
    className: '',
};

export default Container;
