import styled, {css} from 'styled-components';

import {font} from '../../../styles/variables';
import {md} from '../../../styles/media';

export const fontStyleHeadlines = css`
    color: ${font.color.text};
    font-family: ${font.type.serif};
    font-weight: ${(props) => font.weight[props.fontWeight]};
    font-style: ${font.style.normal};
    line-height: 1.5;
    margin-top: ${(props) => (props.hideTopMargin ? '0' : '.5rem')};
    margin-bottom: ${(props) => (props.hideBottomMargin ? '0' : '1rem')};
    text-align: ${(props) => props.align};
    flex-grow: ${(props) => (props.flexGrow ? props.flexGrow : 1)};

    ${md`
		margin-top: ${(props) => (props.hideTopMargin ? '0' : '1rem')};
	`};
`;

export const H1 = styled.h1`
    ${fontStyleHeadlines};
    font-size: ${font.size.xxlarge};
    margin-top: 1.5rem;
    margin-bottom: ${(props) => (props.hideBottomMargin ? '0' : '1.2rem')};

    ${md`
        font-size: 3rem;
    `}
`;

export const H2 = styled.h2`
    ${fontStyleHeadlines};
    font-size: ${font.size.xlarge};

    ${md`
        font-size: ${font.size.xlarge};
    `}
`;

export const H3 = styled.h3`
    ${fontStyleHeadlines};
    font-size: ${font.size.large};

    ${md`
        font-size: ${font.size.large};
    `}
`;

export const H4 = styled.h4`
    ${fontStyleHeadlines};
    font-size: ${font.size.ml};

    ${md`
        font-size: ${font.size.ml};
    `}
`;

export const H5 = styled.h5`
    ${fontStyleHeadlines};
    font-size: ${font.size.medium};

    ${md`
        font-size: ${font.size.medium};
    `}
`;

export const H6 = styled.h6`
    ${fontStyleHeadlines};
    font-size: ${font.size.small};

    ${md`
        font-size: ${font.size.small};
    `}
`;
