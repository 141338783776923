export default {
    title: 'PTA Weiterbildung & Fortbildung für Apotheker | Apotique',
    description:
        'Apotique - Das Portal für PTA Weiterbildung und PTA Fortbildung in der Apotheke! ✓ online ✓ kostenlos ✓ tolle Prämien - Jetzt Prämienpunkte sammeln!',
    keywords: [
        'pta weiterbildung',
        'pta weiterbildungen',
        'weiterbildung pta',
        'pta fortbildung',
        'fortbildung pta',
        'fortbildung apotheker',
        'die pta in der apotheke fortbildung',
        'pta online fortbildung',
        'weiterbildung apotheker',
        'apotheker weiterbildung',
        'pta fortbildung online',
        'weiterbildung für ptas',
        'weiterbildung pharmazie',
        'apotheke fortbildung',
        'pta weiterbildung ernährung',
        'apotheker fortbildung',
        'apothekerfortbildung',
        'arzneimittelkunde für pta',
        'fortbildung apotheke',
        'fortbildungspunkte apotheker',
        'online fortbildung pta',
        'pta weiterbildung zum apotheker',
        'fortbildung für apotheker',
        'fortbildung pharmazie',
        'online fortbildung apotheker',
        'pharmazie weiterbildung',
        'pta aktuell fortbildung',
        'pta fortbildungsmöglichkeiten',
        'pta fortbildungspunkte',
        'pta in der apotheke fortbildung',
        'weiterbildung apotheke',
        'weiterbildung für apotheker',
        'apo fortbildung',
        'apotheker training',
        'pta training',
    ],
};
