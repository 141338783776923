import styled from 'styled-components';

import {font} from '../../../styles/variables';
import {fontStyleBase} from '../../../styles/global';

export const sizes = ['xsmall', 'small', 'medium', 'big'];
const fontSizes = {
    xsmall: '0.7rem',
    small: '0.9rem',
    medium: '1rem',
    big: '1.1rem',
};

export const StyledParagraph = styled.p`
    ${fontStyleBase};
    font-size: ${(props) => fontSizes[props.size]};
    text-align: ${(props) => props.align};
    font-weight: ${(props) => font.weight[props.fontWeight]};
    ${(props) => (props.serifFont ? `font-family: ${font.type.serif}` : '')};
    ${(props) => (props.extraLineSpace ? 'line-height: 1.8' : '')};
    margin: ${(props) => props.margin};
`;
