import React from 'react';

export default {
    headline: (
        <i>
            Belohne Deine <strong>Apothekenteams</strong>
        </i>
    ),
    paragraph1:
        'Hier kannst Du die Apothekenmitarbeiter/innen für die Du zuständig bist, mit Prämienpunkten belohnen. Wähle einfach den Anlass der Punktevergabe, die zugehörige Apotheke und den/die entsprechenden Apothekenmitarbeiter/innen aus.',
    submitButton: 'Punkte vergeben',
    paragraph2:
        'Du willst nachvollziehen, wie Du bisher Deine Punkte vergeben hast? Verschaffe Dir einen Überblick im Archiv: Hier findest Du alle Aktionen mit den zugehörigen Teilnehmern und Deinen vergebenen Punkten.',
    link: 'Zum Punktearchiv',
    feedbackForm: {
        headline: (points) => `${points} Punkte`,
        paragraph1: (points) =>
            `Dein Außendienstler möchte Dir für Deine Teilnahme an der letzten Schulung ${points} Punkte gutschreiben. Wenn Du an der Schulung teilgenommen hast, fülle bitte den folgenden kurzen Fragebogen aus. Im Anschluss erhältst Du die Punkte auf Dein Konto.`,
        questions: [
            {
                question: 'Wie haben Dir die Inhalte der Schulung gefallen?',
                answer1: 'Super',
                answer2: 'Okay',
                answer3: 'Schlecht',
            },
            {
                question: 'Wie hat Dir der Referent gefallen? ',
                answer1: 'Super',
                answer2: 'Okay',
                answer3: 'Schlecht',
            },
            {
                question: 'Wie würdest Du die Schulung bewerten?',
                answer1: 'Super',
                answer2: 'Okay',
                answer3: 'Schlecht',
            },
        ],
        submitButton: 'Abschicken',
        noParticipation: 'Ich habe nicht teilgenommen.',
    },
};
