import styled, {css} from 'styled-components';
import {color, shadow, border} from '../../../styles/variables';

const secondaryButtonStyles = css`
    color: ${color.red};
    background-color: ${color.white};
    border: ${border.red};

    .ap-icon {
        svg {
            fill: ${color.red};
        }
    }

    &:hover {
        color: ${color.white};
        background-color: ${color.red};

        .ap-icon {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;

            svg {
                fill: ${color.white};
            }
        }
        .ap-icon-right {
            width: 1.5rem;
            height: 1.5rem;
            margin-left: 0.5rem;

            svg {
                fill: ${color.white};
            }
        }
    }
`;

const ghostButtonStyles = css`
    color: ${color.white};
    background-color: transparent;
    border: 1px solid ${border.white};

    .ap-icon,
    .ap-icon-right {
        svg {
            fill: ${color.white};
        }
    }

    &:hover {
        color: ${color.white};
        background-color: transparent;
        border: 1px solid ${border.white};
    }
`;

const iconRightButtonStyles = css`
    flex-direction: row-reverse;
    .ap-icon {
        margin-left: 0.5rem;
        margin-right: 0;
    }
`;

const disabledButtonStyles = css`
    opacity: 0.4;
    cursor: not-allowed;

    &:hover {
        background-color: ${(props) =>
            props.secondary ? color.white : color.red};
        ${(props) => (props.secondary ? 'color: ' + color.red : '')};
    }

    &:active,
    &:focus {
        outline: none;
        border: ${(props) =>
            props.secondary ? border.red : border.transparent};
        box-shadow: none;
    }
`;

const ButtonStyles = css`
    height: 48px;
    padding: 0 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${color.white};
    background-color: ${color.red};
    cursor: pointer;
    border: ${border.transparent};
    font-size: 0.9rem;

    .ap-icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;

        svg {
            fill: ${color.white};
        }
    }

    .ap-icon-right {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.5rem;

        svg {
            fill: ${color.white};
        }
    }

    &:hover {
        background-color: ${color.wine};
    }

    &:active,
    &:focus {
        outline: none;
        border: ${border.wine};
        box-shadow: ${shadow.wine};
    }

    ${(props) => props.secondary && secondaryButtonStyles}
    ${(props) => props.ghost && ghostButtonStyles}
    ${(props) => props.iconRight && iconRightButtonStyles}
    ${(props) => props.disabled && disabledButtonStyles}
`;

export const StyledButton = styled.button`
    ${ButtonStyles};
    ${(props) =>
        props.maxContent &&
        css`
            width: max-content;
        `}
`;

//add margin right to visually center Icon + Text in Button
export const Text = styled.span`
    //margin-right: 8px;
    //margin-left: 4px;
`;

export const Circle = styled(StyledButton)`
    border-radius: 50%;
    height: 3.5rem;
    width: 3.5rem;
    padding: 0;

    .ap-icon {
        margin: 0;
    }
`;
