import React from 'react';
import PropTypes from 'prop-types';

import {SlideWrapper} from './Slide.styles';

const Slide = ({children}) => {
    return <SlideWrapper>{children}</SlideWrapper>;
};

Slide.propTypes = {
    children: PropTypes.any,
};

export default Slide;
