import React from 'react';

export default {
    deineDatenUndDu: (
        <>
            <strong>Deine Daten</strong> und Du!
        </>
    ),
    paragraph1:
        'Hier kannst Du Deine persönlichen Daten anpassen und Deine Zugangsdaten ändern. Du hast Deine Apotheke gewechselt? Dann korrigiere hier Deine Apothekenzugehörigkeit. Außerdem kannst Du Deine Interessensauswahl anpassen, wenn Du Dich zu anderen Themen weiterbilden möchtest.',
    personalDataForm: {
        headline: 'Persönliche Daten',
    },
    accessDataForm: {
        headline: 'Zugangsdaten',
    },
    pharmacyDataForm: {
        headline: 'Apothekenzugehörigkeit',
    },
    trainingTopics: {
        headline: 'Meine Interessen',
    },
    personalisierung: {
        headline: (
            <>
                <i>In wenigen Stichen zu Deinem</i>{' '}
                <strong>passgenauen Apotheken-Wissen</strong>
            </>
        ),
        paragraph:
            'Lass uns wissen, welche Inhalte Dich besonders interessieren und wir schneidern Dir Dein persönliches Wissenspaket.',
    },
    preferredCategoriesForm: {
        question: (
            <>
                <strong>
                    Zu welchen Themen möchtest Du Dich weiterbilden?
                </strong>
            </>
        ),
        preferencesHint:
            'Du kannst Deine Präferenzen jederzeit in Deiner persönlichen Apotique anpassen.',
        multiStepSubmitButton: 'Jetzt Maß nehmen',
    },
    userTypeForm: {
        headline: (
            <>
                <strong>Ich bin...</strong>
            </>
        ),
        preferencesHint:
            'Du kannst Deine Präferenzen jederzeit in Deiner persönlichen Apotique anpassen.',
        multiStepSubmitButton: 'Weiter zuschneiden',
    },
    submitButton: 'Änderungen speichern',
    logoutButton: 'Logout',
    logout: 'Ausloggen',
    headerAlt: 'Header-Bild: PTA stellt Daten ein.',
    interimMessage: {
        headline: 'Bitte aktualisiere Deine Apothekenzugehörigkeit',
        paragraph:
            'Auf Deiner Profilseite kannst Du Deine Apotheke eintragen, um alle Funktionen Deiner Apotique zu nutzen.',
        button: 'Zum Profil',
    },
    referralCodeMessage: {
        paragraph:
            'Danke, dass Du der Einladung gefolgt bist. Absolviere ein Training Deiner Wahl. Dein:e Kolleg:in erhält danach 100 Punkte.',
        headline: 'Herzlich Willkommen!',
        button: "Los geht's",
    },
    invitationCodeBanner: {
        title: 'Lade Deine Kolleg:innen zur Apotique ein und erhalte Punkte!',
        referallCodeTitle: 'Dein persönlicher Code:',
        text:
            'Und so funktioniert’s: Verschicke Deinen Einladungscode an Dein:e Kolleg:innen. Wenn Dein Code bei einer Neuanmeldung eingegeben wird und Dein:e Kolleg:in ein beliebiges Lernmodul absolviert hat, werden Dir automatisch 100 Punkte gutgeschrieben. Es sind 300 Punkte möglich.',
        usedCode:
            'Du hast 3 Deiner Kolleg/innen erfolgreich in die Apotique eingeladen! Vielen Dank, dass du uns weiter empfiehlst!',
    },
};
