import styled, {css} from 'styled-components';

import {color, shadow, border, font} from '../../../styles/variables';

export const circleSize = css`
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
`;

const imageHoverStyles = css`
    transform: scale(1.05);

    .ap-custom-label {
        font-weight: ${font.weight.semibold};
        color: ${color.red};
    }

    svg {
        path[class$='stripes'] {
            fill: ${color.red};
        }
    }
`;

export const imageLabelStyles = css`
    display: inline-block;
    position: relative;
    margin: 10px;
    padding: 25px 25px 35px 25px;
    box-shadow: ${shadow.blackLight};
    transition-property: box-shadow, transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    background-color: ${color.white};

    input,
    .ap-custom-radio,
    .ap-custom-checkbox {
        position: absolute;
        margin-right: 0;
        top: 10px;
        right: 10px;
    }

    .ap-custom-label {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        word-break: initial;
    }

    &:hover {
        ${imageHoverStyles}
    }

    ${(props) => props.checked && imageHoverStyles};
`;

const iconHoverStyles = css`
    color: ${color.petrol};

    .ap-icon {
        svg {
            path[class*='cls-1'] {
                fill: ${color.petrol};
            }
        }
    }
`;

export const iconLabelStyles = css`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    color: ${color.grey};
    flex: 0 0 80px;
    max-width: 80px;

    .ap-icon {
        flex: 0 0 24px;
    }

    .ap-custom-label {
        font-size: ${font.size.xsmall};
        font-weight: ${font.weight.semibold};
        text-align: center;
        margin-top: 3px;
    }

    &:not(:last-child) {
        &::after {
            position: absolute;
            height: 2px;
            width: 32px;
            flex: 0 0 32px;
            right: calc(-32px / 2);
            top: calc(24px / 2);
            display: block;
            border-radius: 2px;
            background-color: ${color.grey};
            content: '';
        }
    }

    &:hover {
        ${(props) => !props.checked && iconHoverStyles};
    }

    ${(props) => props.checked && `color: ${color.petrol};`}
`;

export const StyledLabel = styled.label`
    display: flex;
    margin-bottom: 0.5rem;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${(props) =>
        props.disabled &&
        '& {opacity: 40%;}' +
            '* {cursor: not-allowed; opacity: 80%; &:hover * {transition: none!important, background-size: initial}}'}
    margin-bottom: ${(props) => (props.error ? '0.5rem' : '1rem')};

    ${(props) => props.img && imageLabelStyles}
    ${(props) => props.icon && iconLabelStyles}
`;

export const HiddenRadio = styled.input.attrs({type: 'radio'})`
    ${circleSize}
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    margin: 0;
`;

export const StyledRadio = styled.div`
    ${circleSize}
    border-radius: 50%;
    border: ${(props) => (props.checked ? 'none' : border.red)};
    margin-right: 0.5rem;
    background-color: ${color.white};
    transition: all 150ms;

    ${HiddenRadio}:active + &,
    ${HiddenRadio}:focus + & {
        box-shadow: ${(props) => (props.disabled ? 'none' : shadow.wine)};
    }
`;

export const RadioLabel = styled.span`
    line-height: 1.2;
    flex: 1 1 auto;
    max-width: 100%;

    ${HiddenRadio}:active ~ &,
    ${HiddenRadio}:focus ~ & {
        outline: ${(props) =>
            props.disabled ? 'none' : `1px dotted ${color.wine}`};
        outline-offset: 4px;
    }
`;
