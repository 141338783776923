import styled, {css} from 'styled-components';
import {color} from '../../../styles/variables';
import {xlMax} from '../../../styles/media';
import ShopWindow from '../../../assets/images/trainings/img-schaufenster--hintergrund-desktop.png';

export const sectionThemeNames = ['default', 'grey', 'blue'];

const yPaddingStyles = css`
    padding-top: 4rem;
    padding-bottom: 4rem;
`;

const noPaddingStyles = css`
    padding-top: 0;
    padding-bottom: 0;
`;

const fullHeaderStyles = css`
    background-image: url(${ShopWindow});
    ${xlMax`
        background-image: none;
    `};
`;

export const sectionThemes = {
    default: {
        bg: 'transparent',
    },
    grey: {
        bg: color.snow,
    },
    blue: {
        bg: color.lightBlue,
    },
};

export const Section = styled.section`
    position: relative;
    width: 100%;
    background-color: ${(props) => props.theme.bg};
    padding-top: 2rem;
    padding-bottom: 2rem;
    ${(props) => props.padding && `padding: ${props.padding};`}
    ${(props) => props.yPadding && yPaddingStyles};
    ${(props) => props.noPadding && noPaddingStyles};
    ${(props) => props.fullHeader && fullHeaderStyles};
    ${(props) =>
        props.bgImage &&
        css`
            background-image: url(${props.bgImage});
            ${xlMax`
                background-image: none;
            `};
        `};

    ${(props) => props.noLineHeight && 'line-height: 0;'}
`;
