import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';

import {
    StyledLabel,
    HiddenCheckbox,
    StyledCheckbox,
    StyledIcon,
    CheckboxLabel,
    StyledImage,
} from './Checkbox.styles';
import {color} from '../../../styles/variables';
import ErrorMessage from './ErrorMessage';
import {makeUrl} from '../../../model/backend-api';

/* If the Checkbox is an image Checkbox, it needs both an imgDefault
(src string) and imgChecked for hover and checked states */
export const Checkbox = forwardRef((props, ref) => {
    const {
        name,
        label,
        value,
        imgDefault,
        imgChecked,
        disabled,
        onChange,
        errors,
        ...rest
    } = props;
    const error = errors && !!errors[name];
    return (
        <>
            <StyledLabel
                htmlFor={name}
                checked={value}
                error={error}
                disabled={disabled}
                hoverImg={makeUrl(imgChecked)}
                img={makeUrl(imgDefault)}>
                {imgDefault ? (
                    <StyledImage
                        img={
                            value && imgChecked
                                ? makeUrl(imgChecked)
                                : makeUrl(imgDefault)
                        }
                    />
                ) : null}
                <HiddenCheckbox
                    {...rest}
                    ref={ref}
                    id={name}
                    name={name}
                    disabled={disabled}
                    checked={value}
                    onChange={(event) => onChange(event, name)}
                />
                <StyledCheckbox
                    className="ap-custom-checkbox"
                    checked={value}
                    disabled={disabled}>
                    {value && (
                        <StyledIcon
                            name="checkmarkCircleFilled"
                            size="24px"
                            color={color.red}
                        />
                    )}
                </StyledCheckbox>
                <CheckboxLabel className="ap-custom-label" disabled={disabled}>
                    {label}
                </CheckboxLabel>
            </StyledLabel>
            {errors && errors[name] && (
                <ErrorMessage>{errors[name].message}</ErrorMessage>
            )}
        </>
    );
});

Checkbox.propTypes = {
    name: PropTypes.string,
    label: PropTypes.any,
    value: PropTypes.bool,
    imgDefault: PropTypes.string,
    imgChecked: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    errors: PropTypes.object,
};

const ControlledCheckbox = ({control, ...props}) => {
    const {name} = props;
    return (
        <Controller
            name={name}
            control={control}
            render={({value, onChange}) => (
                <Checkbox
                    {...props}
                    value={value}
                    onChange={(event, name) =>
                        onChange(event.target.checked, name)
                    }
                />
            )}
        />
    );
};

ControlledCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
};

export default ControlledCheckbox;
