import {css} from 'styled-components';

import {sm, md, lg, xl, xxl, xxxl} from './media';
import {grid} from './variables';

export const contentMaxWidthsNarrow = css`
    ${sm`
		max-width: ${grid.container.sm};
	`};

    ${md`
		max-width: ${grid.container.md};
	`};

    ${lg`
		max-width: ${grid.container.md};
	`};
`;

export const contentMaxWidthsMedium = css`
    ${sm`
		max-width: ${grid.container.sm};
	`};

    ${md`
		max-width: ${grid.container.md};
	`};

    ${lg`
		max-width: ${grid.container.lg};
	`};

    ${xl`
		max-width: ${grid.container.xl};
	`};
`;

export const contentMaxWidthsWide = css`
    ${md`
        max-width: ${grid.container.md};
    `};

    ${lg`
        max-width: ${grid.container.lg};
    `};

    ${xl`
        max-width: ${grid.container.xl};
    `};

    ${xxl`
        max-width: ${grid.container.xxl};
    `};
`;

export const contentMaxWidthsXtraWide = css`
    ${md`
        max-width: ${grid.container.md};
    `};

    ${lg`
        max-width: ${grid.container.lg};
    `};

    ${xl`
        max-width: ${grid.container.xl};
    `};

    ${xxl`
        max-width: ${grid.container.xxl};
    `};

    ${xxxl`
        max-width: ${grid.container.xxxl};
    `};
`;
