import styled from 'styled-components';

import {font, color, border} from '../../../styles/variables';

export const StyledTextarea = styled.textarea`
    display: block;
    width: 100%;
    padding: 0.75rem 0.5rem;
    color: ${font.color.text};
    font-size: 1rem;
    line-height: 1.2;
    background-color: ${color.white};
    border: ${(props) =>
        props.theme === 'blue'
            ? border.transparent
            : props.error
            ? border.wine
            : border.grey};

    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'auto')};
    ${(props) => (props.disabled ? 'opacity: 40%' : null)};

    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: ${(props) =>
            props.disabled && props.theme === 'blue'
                ? border.transparent
                : props.disabled
                ? border.grey
                : border.petrol};
    }

    ::-webkit-input-placeholder {
        color: ${color.grey};
    }

    :-ms-input-placeholder {
        color: ${color.grey};
    }

    ::placeholder {
        color: ${color.grey};
    }

    &:hover {
        ::-webkit-input-placeholder {
            color: ${(props) => (props.disabled ? color.grey : color.petrol)};
        }

        :-ms-input-placeholder {
            color: ${(props) => (props.disabled ? color.grey : color.petrol)};
        }

        ::placeholder {
            color: ${(props) => (props.disabled ? color.grey : color.petrol)};
        }
    }

    &:active,
    &:focus {
        ::-webkit-input-placeholder {
            color: ${color.grey};
        }

        :-ms-input-placeholder {
            color: ${color.grey};
        }

        ::placeholder {
            color: ${color.grey};
        }
    }
`;
