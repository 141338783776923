import styled, {css} from 'styled-components';
import {xs, sm, md, lg, xl, xxl} from '../../../styles/media';

const ColStyles = css`
    flex-basis: 100%;
    flex-grow: 1;
    max-width: 100%;
`;

export const Column = styled.div`
    position: relative;
    padding: 1rem;

    ${(props) =>
        props.xs || props.sm || props.md || props.lg || props.xl || props.xxl
            ? ColStyles
            : null};

    ${xs`
		${(props) => props.xs && `flex: 0 1 ${(props.xs / 12) * 100}%;`};
		${(props) => props.xs && `max-width: ${(props.xs / 12) * 100}%;`};
		${(props) =>
            props.offset && props.offset.xs
                ? `margin-left: ${(props.offset.xs / 12) * 100}%;`
                : ``};
	`};

    ${sm`
		${(props) => props.sm && `flex: 0 1 ${(props.sm / 12) * 100}%;`};
		${(props) => props.sm && `max-width: ${(props.sm / 12) * 100}%;`};
		${(props) =>
            props.offset && props.offset.sm
                ? `margin-left: ${(props.offset.sm / 12) * 100}%;`
                : ``};
	`};

    ${md`
		${(props) => props.md && `flex: 0 1 ${(props.md / 12) * 100}%;`};
		${(props) => props.md && `max-width: ${(props.md / 12) * 100}%;`};
		${(props) =>
            props.offset && props.offset.md
                ? `margin-left: ${(props.offset.md / 12) * 100}%;`
                : ``};
	`};

    ${lg`
		${(props) => props.lg && `flex: 0 1 ${(props.lg / 12) * 100}%;`};
		${(props) => props.lg && `max-width: ${(props.lg / 12) * 100}%;`};
		${(props) =>
            props.offset && props.offset.lg
                ? `margin-left: ${(props.offset.lg / 12) * 100}%;`
                : ``};
	`};

    ${xl`
		${(props) => props.xl && `flex: 0 1 ${(props.xl / 12) * 100}%;`};
		${(props) => props.xl && `max-width: ${(props.xl / 12) * 100}%;`};
		${(props) =>
            props.offset && props.offset.xl
                ? `margin-left: ${(props.offset.xl / 12) * 100}%;`
                : ``};
	`};

    ${xxl`
		${(props) => props.xxl && `flex: 0 1 ${(props.xxl / 12) * 100}%;`};
		${(props) => props.xxl && `max-width: ${(props.xxl / 12) * 100}%;`};
		${(props) =>
            props.offset && props.offset.xxl
                ? `margin-left: ${(props.offset.xxl / 12) * 100}%;`
                : ``};
	`};
`;
