import React from 'react';
import PropTypes from 'prop-types';

import {StyledDivider} from './styles';
import {color} from '../../../styles/variables';
import {sectionThemeNames} from '../../layout/Grid/Section.styles';

/* defaultProps does not evaluate color.lightBlue, but prints it as a string;
this is a workaround!
ToDo: Check what's going on when we have time! */
const defaultColor = color.lightBlue;

const Divider = ({dashed, color, theme}) => {
    return <StyledDivider dashed={dashed} color={color} theme={theme} />;
};

Divider.propTypes = {
    dashed: PropTypes.bool,
    color: PropTypes.string,
    theme: PropTypes.oneOf(sectionThemeNames),
};

Divider.defaultProps = {
    color: defaultColor,
};

export default Divider;
