import styled from 'styled-components';

import {color, shadow, border} from '../../../styles/variables';
import Icon from '../Icon';
import {circleSize, imageLabelStyles} from './RadioOption.styles';

export const StyledImage = styled.div`
    background-image: url(${(props) => props.img});
    width: 120px;
    height: 120px;
`;

export const StyledLabel = styled.label`
    display: flex;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${(props) =>
        props.disabled &&
        '& {opacity: 40%;}' +
            '* {cursor: not-allowed; opacity: 80%; * {background: none!important;} &:hover * {background: none}}'}
    margin-bottom: ${(props) => (props.error ? '0.5rem' : '1rem')};

    + .ap-error {
        margin-bottom: 1.5rem;
        margin-left: 2px;

        small {
            margin-left: 12px;
        }
    }

    &:hover ${StyledImage} {
        background-image: url(${(props) => props.hoverImg});
    }

    ${(props) => props.img && imageLabelStyles}
`;

export const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
    ${circleSize}
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    margin: 0;
`;

export const StyledIcon = styled(Icon)``;

export const StyledCheckbox = styled.div`
    ${circleSize}
    border-radius: 50%;
    border: ${(props) => (props.checked ? 'none' : border.red)};
    margin-right: 0.5rem;
    transition: all 150ms;

    ${HiddenCheckbox}:active + &,
    ${HiddenCheckbox}:focus + & {
        box-shadow: ${(props) => (props.disabled ? 'none' : shadow.wine)};
    }
`;

export const CheckboxLabel = styled.span`
    line-height: 1.2;
    flex: 1 1 auto;
    max-width: 100%;

    ${HiddenCheckbox}:active ~ &,
    ${HiddenCheckbox}:focus ~ & {
        outline: ${(props) =>
            props.disabled ? 'none' : `1px dotted ${color.wine}`};
        outline-offset: 4px;
    }
`;
