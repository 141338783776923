import styled, {css} from 'styled-components';
import {color} from '../../../styles/variables';

export const BurgerStyled = styled.button`
    padding: 0;
    display: block;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;

    /* Normalize (<button>) */
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:hover {
        opacity: 0.7;
    }

    &:focus {
        outline: 0;
    }

    ${(props) =>
        props.isOpen
            ? css`
                  &:hover {
                      opacity: 0.7;
                  }

                  .hamburger-inner,
                  .hamburger-inner::before,
                  .hamburger-inner::after {
                      background-color: ${color.wine};
                  }
              `
            : ``};
`;

export const BurgerBox = styled.span`
    display: block;
    width: 2rem;
    height: 24px;
    position: relative;
    pointer-events: none;
`;

export const BurgerInner = styled.span`
    display: block;
    top: 50%;
    margin-top: ${4 / -2}px;
    pointer-events: none;

    &,
    &::before,
    &::after {
        width: 2rem;
        height: 4px;
        background-color: ${color.red};
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: '';
        display: block;
    }

    &::before {
        top: ${(6 + 4) * -1}px;
    }

    &::after {
        bottom: ${(6 + 4) * -1}px;
    }

    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

    &::before,
    &::after {
        transition-duration: 0s;
        transition-delay: 0.1s;
        transition-timing-function: linear;
    }

    &::before {
        transition-property: top, opacity;
    }

    &::after {
        transition-property: bottom, transform;
    }

    ${(props) =>
        props.isOpen
            ? css`
                  transform: rotate(765deg);
                  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

                  &::before,
                  &::after {
                      transition-delay: 0s;
                  }

                  &::before {
                      top: 0;
                      opacity: 0;
                  }

                  &::after {
                      bottom: 0;
                      transform: rotate(90deg);
                  }
              `
            : ``};
`;
