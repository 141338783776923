import styled from 'styled-components';
import Modal from 'react-modal';
import Icon from '../Icon';

import {color, outline} from '../../../styles/variables';

export const Overlay = styled(Modal).attrs((props) => ({
    style: {
        overlay: {
            backgroundColor: color.darkgreyLucid,
            opacity: props.opacity,
            zIndex: 999,
            transition: 'all 500ms ease-in-out',
        },
        content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: 'calc(100% - 40px)',
            width: '100%',
            maxWidth: props.maxWidth,
            overflow: 'auto',
        },
    },
}))`
    &:focus {
        outline: ${outline.focus};
    }
`;

export const ModalContainer = styled.div`
    position: relative;
    background-color: ${color.lightBlue};
    padding: 0;

    ${(props) => (props.showPadding ? `padding: 1rem;` : null)};
`;

export const ModalWrapper = styled.div``;

export const CloseButton = styled.span`
    position: absolute;
    cursor: pointer;
    background-color: ${color.red};
    right: 0;
    top: 0;
    width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 1;
`;

export const StyledIcon = styled(Icon)`
    color: ${color.white};
    cursor: pointer;
`;
