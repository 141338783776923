import React, {forwardRef, useState} from 'react';
import PropTypes from 'prop-types';
import ReactSelect, {components} from 'react-select';
import {Controller} from 'react-hook-form';

import {sectionThemeNames} from '../../layout/Grid/Section.styles';
import {
    IconWrapper,
    selectStylesSimple,
    selectStylesMulti,
    selectColorThemeSimple,
    selectColorThemeMulti,
} from './Select.styles';
import Icon from '../Icon';
import Label from './Label';
import ErrorMessage from './ErrorMessage';
import {color} from '../../../styles/variables';

const {Option, ValueContainer} = components;

const CustomOption = ({isSelected, label, ...props}) => (
    <Option {...props}>
        <IconWrapper>
            <Icon
                name={isSelected ? 'checkmarkCircleFilled' : 'circle'}
                size="24px"
                color={color.red}
            />
        </IconWrapper>
        {label}
    </Option>
);

CustomOption.propTypes = {
    isSelected: PropTypes.bool,
    label: PropTypes.string,
};

const CustomMultiValueContainer = ({
    children,
    getValue,
    selectProps,
    ...props
}) => {
    let valueCount = getValue().length;
    return (
        <ValueContainer {...props}>
            {valueCount > 0
                ? `${selectProps.multiValueLabel} (${valueCount})`
                : selectProps.placeholder}
            {React.cloneElement(children[1])}
        </ValueContainer>
    );
};

CustomMultiValueContainer.propTypes = {
    children: PropTypes.any,
    getValue: PropTypes.func,
    selectProps: PropTypes.any,
};

const NumberInput = (props) => {
    const {selectProps} = props;
    return (
        <components.Input
            {...props}
            onInput={(e) => {
                if (selectProps.maxLength) {
                    e.target.value = e.target.value.slice(
                        0,
                        selectProps.maxLength
                    );
                }
            }}
            type="number"
            className="app-number-input"
        />
    );
};

NumberInput.propTypes = {
    selectProps: PropTypes.shape({
        isNumber: PropTypes.bool,
        maxLength: PropTypes.number,
    }),
};

export const Select = forwardRef(
    (
        {
            name,
            label,
            theme,
            sectionTheme,
            options,
            multi,
            isDisabled,
            placeholder,
            errors,
            isNumber,
            maxLength,
            ...rest
        },
        ref
    ) => {
        const error = errors && !!errors[name];
        return (
            <>
                {label && (
                    <Label
                        htmlFor={name}
                        label={label}
                        error={error}
                        disabled={isDisabled}
                    />
                )}
                <ReactSelect
                    {...rest}
                    ref={ref}
                    name={name}
                    theme={
                        multi ? selectColorThemeMulti : selectColorThemeSimple
                    }
                    sectionTheme={sectionTheme}
                    options={options}
                    isMulti={multi}
                    isDisabled={isDisabled}
                    placeholder={placeholder}
                    openMenuOnFocus
                    closeMenuOnSelect={!multi}
                    /* add menuIsOpen prop so menu stays open during styling
                        menuIsOpen
                    */
                    error={error}
                    hideSelectedOptions={false}
                    styles={multi ? selectStylesMulti : selectStylesSimple}
                    maxLength={maxLength}
                    components={{
                        ...(isNumber && {Input: NumberInput}),
                        IndicatorSeparator: () => null,
                        ...(multi
                            ? {
                                  ValueContainer: CustomMultiValueContainer,
                                  Option: CustomOption,
                              }
                            : null),
                    }}
                />
                {error && <ErrorMessage>{errors[name].message}</ErrorMessage>}
            </>
        );
    }
);

Select.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    sectionTheme: PropTypes.oneOf(sectionThemeNames),
    theme: PropTypes.object,
    options: PropTypes.array,
    multi: PropTypes.bool,
    multiValueLabel: PropTypes.string,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    errors: PropTypes.object,
    isNumber: PropTypes.bool,
    maxLength: PropTypes.number,
};

Select.defaultProps = {
    sectionTheme: 'default',
    placeholder: 'Bitte wählen',
    isNumber: false,
    maxLength: null,
    multiValueLabel: 'Thema wählen',
};

const ControlledSelect = ({
    control,
    handleChange,
    onInputChange,
    clearValueOnFocus,
    onMenuClose,
    onBlur,
    onMenuScrollToBottom,
    required,
    ...props
}) => {
    const {name} = props;
    const [isFocused, setFocus] = useState(false);

    return (
        <Controller
            name={name}
            control={control}
            value={null}
            rules={{required: required}}
            render={({value, onChange}) => {
                return (
                    <Select
                        {...props}
                        value={clearValueOnFocus && isFocused ? null : value}
                        onFocus={() => setFocus(true)}
                        onMenuClose={onMenuClose}
                        onMenuScrollToBottom={onMenuScrollToBottom}
                        onBlur={() => {
                            setFocus(false);
                            onBlur();
                        }}
                        isOptionDisabled={(option) => option.disabled}
                        onInputChange={onInputChange}
                        onChange={(selection) => {
                            handleChange(selection);
                            return onChange(selection);
                        }}
                    />
                );
            }}
        />
    );
};

ControlledSelect.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    handleChange: PropTypes.func,
    onBlur: PropTypes.func,
    onInputChange: PropTypes.func,
    onMenuScrollToBottom: PropTypes.func,
    onMenuClose: PropTypes.func,
    clearValueOnFocus: PropTypes.bool,
    required: PropTypes.bool,
};

ControlledSelect.defaultProps = {
    handleChange: () => {},
    onInputChange: () => {},
    onMenuClose: () => {},
    onBlur: () => {},
    onMenuScrollToBottom: () => {},
    clearValueOnFocus: false,
    required: false,
};

export default ControlledSelect;
