import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import {Navigation, Item, DropDown, DropDownItem} from './MainNav.styles';
import {routes} from '../../../model/navigation';
import {matchLocation} from '../../../utils/route-utils';
import lang from './MainNav.lang';
import Link from '../../components/Link';
import {Roles} from '../../../model/profile/';

const MainNav = ({location, userRoles, loggedIn}) => {
    const [dropdown, setDropdown] = useState(false);
    const apotiqueDropdown = useRef();

    const handleClickOutside = (e) => {
        if (
            apotiqueDropdown &&
            apotiqueDropdown.current &&
            apotiqueDropdown.current.contains(e.target)
        ) {
            return;
        }
        setDropdown(false);
    };

    useEffect(() => {
        if (dropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const openApotiqueDropdown = (e) => {
        e.preventDefault();
        setDropdown(true);
    };

    return (
        <Navigation className="ap-nav ap-nav--main">
            <Item key={routes.trainings}>
                <Link
                    href={routes.trainings}
                    active={matchLocation(routes.trainings, location)}>
                    {lang.trainings}
                </Link>
            </Item>
            <Item key={routes.praemien}>
                <Link
                    href={routes.praemien}
                    active={matchLocation(routes.praemien, location)}>
                    {lang.praemien}
                </Link>
            </Item>
            {loggedIn && userRoles.includes(Roles.SALES_REPRESENTATIVE) && (
                <Item key={routes.news}>
                    <Link
                        href={routes.news}
                        active={matchLocation(routes.news, location)}>
                        {lang.news}
                    </Link>
                </Item>
            )}
            {loggedIn && !userRoles.includes(Roles.SALES_REPRESENTATIVE) && (
                <Item key={routes.deineApotique}>
                    <Link
                        onClick={openApotiqueDropdown}
                        href="/"
                        active={[
                            routes.team,
                            routes.aktionen,
                            routes.punktehistorie,
                            routes.news,
                        ].some((route) => matchLocation(route, location))}>
                        {lang.deineApotique}
                    </Link>
                    <DropDown ref={apotiqueDropdown} open={dropdown}>
                        <DropDownItem>
                            <Link
                                href={routes.team}
                                active={matchLocation(routes.team, location)}>
                                {lang.deinTeam}
                            </Link>
                        </DropDownItem>
                        <DropDownItem>
                            <Link
                                href={routes.aktionen}
                                active={matchLocation(
                                    routes.aktionen,
                                    location
                                )}>
                                {lang.aktionen}
                            </Link>
                        </DropDownItem>
                        <DropDownItem>
                            <Link
                                href={routes.news}
                                active={matchLocation(routes.news, location)}>
                                {lang.news}
                            </Link>
                        </DropDownItem>
                        <DropDownItem>
                            <Link
                                href={routes.punktehistorie}
                                active={matchLocation(
                                    routes.punktehistorie,
                                    location
                                )}>
                                {lang.punktehistorie}
                            </Link>
                        </DropDownItem>
                    </DropDown>
                </Item>
            )}
        </Navigation>
    );
};

MainNav.propTypes = {
    location: PropTypes.object,
    userRoles: PropTypes.array,
    loggedIn: PropTypes.bool,
};

MainNav.defaultProps = {
    userRoles: [],
};

export default MainNav;
