import React from 'react';
import PropTypes from 'prop-types';

import {Button} from './BackToTop.styles';
import Icon from '../Icon';

const BackToTop = ({onClick, show}) => (
    <Button onClick={onClick} show={show}>
        <Icon name="arrowForward" size="2rem" />
    </Button>
);

BackToTop.propTypes = {
    onClick: PropTypes.func,
    show: PropTypes.bool,
};

BackToTop.defaultProps = {
    show: false,
};

export default BackToTop;
