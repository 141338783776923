import styled from 'styled-components';
import {xl} from '../../../styles/media';

export const ApotiqueLogo = styled.div`
    width: 100px;
    height: ${(props) => (props.withSubtitle ? `35px` : `24px`)};

    ${xl`
        width: 150px;
        height: ${(props) => (props.withSubtitle ? `55px` : `33px`)};
    `}

    .ap-link {
        &,
        &:visited,
        &:active,
        &:focus,
        &:hover {
            .ap-text {
                background: none;
            }
        }
    }

    svg {
        width: 100%;
        height: 100%;
    }
`;
