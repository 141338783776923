import React from 'react';
import PropTypes from 'prop-types';
import {Column as ColumnStyled} from './Column.styles';

const Column = ({
    children,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    offset,
    className,
    style,
}) => {
    const columnProps = {
        xs,
        sm,
        md,
        lg,
        xl,
        xxl,
        offset,
        style,
    };

    return (
        <ColumnStyled className={`ap-col ${className}`} {...columnProps}>
            {children}
        </ColumnStyled>
    );
};

Column.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    xxl: PropTypes.number,
    offset: PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number,
        xxl: PropTypes.number,
    }),
    style: PropTypes.object,
};

Column.defaultProps = {
    className: '',
};

export default Column;
