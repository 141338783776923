import React from 'react';
import Link from '../../ui/components/Link';
import {routes} from '../../model/navigation';
import {close as closeLoginLightbox} from '../../model/login-lightbox';

export default {
    headline: (
        <>
            <strong>Login</strong>
        </>
    ),
    submitButton: 'Einloggen',
    resetPasswordLink: (dispatch) => (
        <Link
            href={routes.passwort_vergessen}
            onClick={() => dispatch(closeLoginLightbox())}>
            Passwort vergessen?
        </Link>
    ),
    noAccountYet: (dispatch) => (
        <>
            Du hast noch keinen Account? <br />
            <Link
                href={routes.registrieren}
                onClick={() => dispatch(closeLoginLightbox())}
                className="ap-link--block">
                Jetzt registrieren!
            </Link>
        </>
    ),
};
