import React from 'react';
import PropTypes from 'prop-types';

import {Wrapper, StyledIcon, StyledError} from './ErrorMessage.styles';

import {color} from '../../../styles/variables';

const ErrorMessage = ({children, className}) => {
    return (
        <Wrapper className={className}>
            <StyledIcon name="error" size="20px" color={color.wine} />
            <StyledError>{children}</StyledError>
        </Wrapper>
    );
};

ErrorMessage.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};

ErrorMessage.defaultProps = {
    className: 'ap-error',
};

export default ErrorMessage;
