import styled, {css} from 'styled-components';

import {
    contentMaxWidthsNarrow,
    contentMaxWidthsMedium,
    contentMaxWidthsWide,
    contentMaxWidthsXtraWide,
} from '../../../styles/layout-utils';
import {sm} from '../../../styles/media';

export const contentMaxWidths = ['xtra-wide', 'wide', 'medium', 'narrow'];

const noGutter = css`
    padding: 0;
    ${sm`
        padding: 0;
    `}
    .ap-row {
        margin: 0;
    }

    .ap-col {
        padding: 0;
    }
`;

const doubleGutter = css`
    padding: 2rem 4rem;

    .ap-row {
        margin: 0 -2rem;
    }

    .ap-col {
        padding: 2rem;
    }
`;

export const Container = styled.div`
    width: 100%;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;

    ${sm`
        padding: 1rem 2rem;
    `}

    ${(props) => (props.width === 'xtra-wide' ? contentMaxWidthsXtraWide : ``)};
    ${(props) => (props.width === 'wide' ? contentMaxWidthsWide : ``)};
    ${(props) => (props.width === 'medium' ? contentMaxWidthsMedium : ``)};
    ${(props) => (props.width === 'narrow' ? contentMaxWidthsNarrow : ``)};
    ${(props) => (props.noGutter ? noGutter : ``)};
    ${(props) => (props.doubleGutter ? doubleGutter : ``)};
`;
