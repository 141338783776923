import styled from 'styled-components';

import {border, color, font} from '../../../styles/variables';

export const IconWrapper = styled.div`
    margin-right: 0.5rem;
`;

const menuStyles = {
    borderRadius: 0,
    width: '90%',
    top: 'unset',
};

export const selectColorThemeSimple = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: color.lightPetrol,
        primary50: color.lightPetrol,
        primary: color.petrol,
    },
});

export const selectColorThemeMulti = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: color.snow,
        primary50: color.snow,
        primary: color.white,
        neutral5: font.color.text,
    },
});

const hoverStylesSimple = {
    backgroundColor: color.white,
    '*': {color: color.petrol},
    border: border.petrol,
    boxShadow: 'none',
    outline: 'none',
    cursor: 'pointer',
};

const focusStylesSimple = {
    backgroundColor: color.petrol,
    color: color.white,
    '*': {color: color.white},
    border: border.petrol,
    boxShadow: 'none',
    outline: 'none',
    cursor: 'pointer',
    'div[class$="singleValue"]': {color: color.white},
    ':hover': {
        backgroundColor: color.petrol,
        color: color.white,
        '*': {color: color.white},
    },
};

const disabledStyles = {
    opacity: '40%',
    cursor: 'not-allowed',
    ':hover': {
        backgroundColor: color.white,
        color: font.color.text,
        cursor: 'not-allowed',
        '*': {color: font.color.text},
    },
};

export const selectStylesSimple = {
    container: (provided, state) => ({
        ...(state.isDisabled ? {pointerEvents: 'visible'} : null),
    }),
    control: (provided, state) => ({
        ...provided,
        height: '48px',
        backgroundColor: color.white,
        fontSize: '1rem',
        lineHeight: '1.2',
        borderRadius: 0,
        border:
            state.selectProps.sectionTheme === 'blue'
                ? border.transparent
                : state.selectProps.error
                ? border.wine
                : border.grey,
        ':hover': {...hoverStylesSimple},
        ...(state.isFocused ? focusStylesSimple : null),
        ...(state.isDisabled ? disabledStyles : null),
    }),
    placeholder: (provided, state) => ({
        ...provided,
        ...(state.isFocused ? {color: color.white} : {color: font.color.text}),
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: font.color.text,
        transition: 'transform .3s ease-in-out',
        transform: state.isFocused ? 'rotate(180deg)' : null,
        ...(state.isDisabled ? {opacity: '40%'} : null),
    }),
    menu: (provided) => ({
        ...provided,
        ...menuStyles,
    }),
    menuList: (provided) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    option: (provided) => ({
        ...provided,
        lineHeight: '1.5',
        ':hover': {
            cursor: 'pointer',
        },
    }),
};

const focusHoverStylesMulti = {
    backgroundColor: color.darkBlue,
    color: color.white,
    'div[class$="indicatorContainer"]': {color: color.white},
    border: border.transparent,
    boxShadow: 'none',
    outline: 'none',
    cursor: 'pointer',
};

export const selectStylesMulti = {
    container: (provided, state) => ({
        ...(state.isDisabled ? {pointerEvents: 'visible'} : null),
    }),
    control: (provided, state) => ({
        ...provided,
        height: '48px',
        backgroundColor: state.hasValue ? color.darkBlue : color.white,
        color: state.hasValue ? color.white : font.color.text,
        fontSize: '1rem',
        lineHeight: '1.2',
        borderRadius: 0,
        border:
            state.selectProps.sectionTheme === 'blue'
                ? border.transparent
                : state.selectProps.error
                ? border.wine
                : border.grey,
        ':hover': {...focusHoverStylesMulti},
        'div[class$="indicatorContainer"]': state.hasValue
            ? {color: color.white}
            : {},
        ...(state.isFocused ? focusHoverStylesMulti : null),
        ...(state.isDisabled ? disabledStyles : null),
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: color.grey,
        ':hover': {color: color.white},
        ...(state.isFocused ? {color: color.white} : {}),
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.hasValue ? color.white : font.color.text,
        transition: 'transform .3s ease-in-out',
        transform: state.isFocused ? 'rotate(180deg)' : null,
        ...(state.isDisabled ? {opacity: '40%'} : null),
    }),
    menu: (provided) => ({
        ...provided,
        ...menuStyles,
    }),
    menuList: (provided) => ({
        ...provided,
        padding: '1px 6px',
    }),
    option: (provided) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        borderBottom: border.fog,
        lineHeight: '1.5',
        ':last-child': {
            border: 'none',
        },
        ':focus': {
            outline: `1px dotted ${color.wine}`,
        },
        ':active': {
            outline: `1px dotted ${color.wine}`,
        },
        ':hover': {
            cursor: 'pointer',
        },
    }),
};
