import React from 'react';
import PropTypes from 'prop-types';

import {Column, Container, Row} from '../../../layout/Grid';
import Link from '../../../components/Link';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import {routes} from '../../../../model/navigation';
import lang from '../../../../lang/pages/profil.lang';

export const InterimMessage = ({onButtonClick}) => {
    return (
        <Container width="narrow" className="text-center">
            <Row>
                <Column xs={12}>
                    <Headline type="h5" fontWeight="bold">
                        {lang.interimMessage.headline}
                    </Headline>
                    <Paragraph margin="0">
                        {lang.interimMessage.paragraph}
                    </Paragraph>
                </Column>
                <Column xs={12}>
                    <Link
                        showAsButton
                        href={routes.profil}
                        onClick={onButtonClick}>
                        {lang.interimMessage.button}
                    </Link>
                </Column>
            </Row>
        </Container>
    );
};

InterimMessage.propTypes = {
    onButtonClick: PropTypes.func,
};

export default InterimMessage;
