import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';

import RadioOption from './RadioOption';
import {Wrapper} from './RadioGroup.styles';
import ErrorMessage from './ErrorMessage';

export const RadioGroup = forwardRef((props, ref) => {
    const {
        name,
        imgRadio,
        iconRadio,
        value,
        isDisabled,
        onChange,
        children,
        errors,
    } = props;
    const error = errors && !!errors[name];
    return (
        <>
            <Wrapper imgRadio={imgRadio} iconRadio={iconRadio}>
                {children.map((child, index) => (
                    <RadioOption
                        {...child.props}
                        ref={ref}
                        key={`${name}-${child.props.value}`}
                        id={`${name}-${child.props.value}`}
                        name={name}
                        disabled={isDisabled}
                        checked={child.props.value === value}
                        onChange={onChange}
                    />
                ))}
            </Wrapper>
            {error && <ErrorMessage>{errors[name].message}</ErrorMessage>}
        </>
    );
});

RadioGroup.propTypes = {
    name: PropTypes.string.isRequired,
    imgRadio: PropTypes.bool,
    iconRadio: PropTypes.bool,
    value: PropTypes.string,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    errors: PropTypes.object.isRequired,
    children: PropTypes.any, // TODO: custom validator, allow only RadioOptions
};

const ControlledRadioGroup = ({control, ...props}) => {
    return <Controller as={RadioGroup} control={control} {...props} />;
};

ControlledRadioGroup.propTypes = {
    control: PropTypes.object.isRequired,
};

export default ControlledRadioGroup;
