import React from 'react';
import PropTypes from 'prop-types';

import {matchMedia} from '../../../styles/media';

export const initThrottledResizeEvents = () => {
    const throttle = function (type, name, obj) {
        if (typeof window === 'object') {
            obj = obj || window;
            let running = false;
            const func = function () {
                if (running) {
                    return;
                }
                running = true;
                requestAnimationFrame(function () {
                    let event = null;
                    if (typeof CustomEvent === 'function') {
                        event = new CustomEvent(name);
                    } else {
                        event = document.createEvent('Event');
                        event.initEvent(name, true, true);
                    }
                    obj.dispatchEvent(event);
                    running = false;
                });
            };
            obj.addEventListener(type, func);
        }
    };
    throttle('resize', 'throttledResize');
};

if (typeof window === 'object') {
    initThrottledResizeEvents();
}

export default class ResponsiveSwitch extends React.Component {
    state = {matches: {}};

    onResize = () => {
        this.setState({
            matches: matchMedia(),
        });
    };

    componentDidMount = () => {
        window.addEventListener('throttledResize', this.onResize);
        this.onResize();
    };

    componentWillUnmount = () => {
        window.removeEventListener('throttledResize', this.onResize);
    };

    render() {
        const {children} = this.props;
        const {matches} = this.state;
        return children && children(matches);
    }
}

ResponsiveSwitch.propTypes = {
    children: PropTypes.any,
};
