import PropTypes from 'prop-types';
import React from 'react';

import {StyledLabel} from './Label.styles';

const Label = ({htmlFor, label, error, disabled}) => (
    <StyledLabel htmlFor={htmlFor} error={error} disabled={disabled}>
        {label}
    </StyledLabel>
);

Label.propTypes = {
    label: PropTypes.string,
    htmlFor: PropTypes.string,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default Label;
