import styled from 'styled-components';
import {color, navbarHeight, zindex} from '../../../styles/variables';
import {xl} from '../../../styles/media';

export const BurgerMenu = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: ${color.white};
    transform: ${(props) =>
        props.isOpen ? `translate(0, 0)` : `translate(100%, 0)`};
    transform-origin: 100% 0;
    opacity: ${(props) => (props.isOpen ? `1` : `0`)};
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    z-index: ${zindex.burger};
    overflow-y: auto;
`;

export const Wrapper = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${navbarHeight};
    padding: 0 1rem;
    display: flex;
    align-items: center;
    z-index: ${zindex.navbar};
    background-color: ${color.white};
    ${(props) =>
        props.hideShadow
            ? `box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0);`
            : `box-shadow: 0 2px 4px 0 rgba(122,169,189,0.52);`};

    .ap-container {
        padding-top: 0;
        padding-bottom: 0;
    }

    .ap-row {
        justify-content: space-between;
        align-items: center;
    }

    .ap-logo {
        ${xl`
            position: absolute;
            top: 62%;
            left: 50%;
            transform: translate(-50%, -50%);
        `}
    }
`;
