import styled from 'styled-components';

import {imageWrapperStyles} from './RadioGroup.styles';

export const Wrapper = styled.div`
    ${(props) => props.imgCheckbox && imageWrapperStyles};

    + .ap-error {
        justify-content: center;
    }
`;
