import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import lang from '../../../../lang/pages/punktevergabe.lang';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import {ReactComponent as ThreeStarsSvg} from '../../../../assets/images/trainings/img-overlay--punkte.svg';
import Form, {RadioGroup, RadioOption} from '../../../components/Form';
import {Section, Container, Row, Column} from '../../../layout/Grid';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import Divider from '../../../components/Divider';
import Button from '../../../components/Button';
import Link from '../../../components/Link';
import {feedbackValidator} from '../../../../utils/form-validation';
import {
    selectLatestPendingReceipt,
    confirmPendingTransaction,
    rejectPendingTransaction,
} from '../../../../model/points-receipt';

const validationSchema = yup.object().shape({
    feedbackQuestion1: feedbackValidator,
    feedbackQuestion2: feedbackValidator,
    feedbackQuestion3: feedbackValidator,
});

export const FeedbackForm = ({onSubmit, onReject, theme, pointsAssigned}) => {
    const defaultValues = {
        feedbackQuestion1: '',
        feedbackQuestion2: '',
        feedbackQuestion3: '',
    };

    const {control, handleSubmit, errors} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const feedbackFormData = lang.feedbackForm;
    return (
        <Section theme={theme}>
            <Form theme={theme} onSubmit={handleSubmit(onSubmit)}>
                <Container width="narrow" className="text-center">
                    <Row>
                        <Column xs={12}>
                            <ThreeStarsSvg />
                            <Headline type="h5" fontWeight="bold">
                                {lang.feedbackForm.headline(pointsAssigned)}
                            </Headline>
                            <Paragraph>
                                {feedbackFormData.paragraph1(pointsAssigned)}
                            </Paragraph>
                        </Column>
                        {feedbackFormData.questions.map((question, index) => {
                            let count = index + 1;
                            return (
                                <Column xs={12} key={index}>
                                    <Paragraph>{question.question}</Paragraph>
                                    <RadioGroup
                                        iconRadio
                                        name={'feedbackQuestion' + count}
                                        control={control}
                                        errors={errors}>
                                        <RadioOption value="super" icon="happy">
                                            {question.answer1}
                                        </RadioOption>
                                        <RadioOption
                                            value="okay"
                                            icon="neutral">
                                            {question.answer2}
                                        </RadioOption>
                                        <RadioOption
                                            value="schlecht"
                                            icon="sad">
                                            {question.answer3}
                                        </RadioOption>
                                    </RadioGroup>
                                    {count <
                                        feedbackFormData.questions.length && (
                                        <Divider theme={theme} dashed />
                                    )}
                                </Column>
                            );
                        })}
                        <Column xs={12}>
                            <Button
                                type="submit"
                                label={feedbackFormData.submitButton}
                                className="ap-button"
                            />
                        </Column>
                        <Column xs={12}>
                            <Link href="#" onClick={onReject}>
                                {feedbackFormData.noParticipation}
                            </Link>
                        </Column>
                    </Row>
                </Container>
            </Form>
        </Section>
    );
};

FeedbackForm.propTypes = {
    onSubmit: PropTypes.func,
    onReject: PropTypes.func,
    theme: PropTypes.oneOf(sectionThemeNames),
    pointsAssigned: PropTypes.string,
};

FeedbackForm.defaultProps = {
    onSubmit: () => {},
    onReject: () => {},
    theme: 'default',
};

const ConnectedFeedbackForm = (props) => {
    const dispatch = useDispatch();
    const latestPendingReceipt = useSelector(selectLatestPendingReceipt);
    if (latestPendingReceipt.type !== 'manual') {
        return null;
    }
    const handleSubmit = ({
        feedbackQuestion1,
        feedbackQuestion2,
        feedbackQuestion3,
    }) => {
        dispatch(
            confirmPendingTransaction(
                latestPendingReceipt.id,
                feedbackQuestion1,
                feedbackQuestion2,
                feedbackQuestion3
            )
        );
    };
    const handleReject = () => {
        dispatch(rejectPendingTransaction(latestPendingReceipt.id));
    };
    return (
        <FeedbackForm
            {...props}
            onSubmit={handleSubmit}
            onReject={handleReject}
            pointsAssigned={latestPendingReceipt.change}
        />
    );
};

export default ConnectedFeedbackForm;
