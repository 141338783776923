import * as yup from 'yup';

export const usernameValidatorStrict = yup
    .string()
    .required('Bitte trage Deinen Usernamen ein.')
    .matches(
        /^[A-Za-zäÄöÖüÜ]([A-Za-z0-9äÄöÖüÜ._]){2,19}$/,
        'Dein Benutzername muss mit einem Buchstaben beginnen, zwischen 3 und 20 Zeichen lang sein und darf bis auf . und _ keine Leer- oder Sonderzeichen enthalten.'
    );

export const identifierValidatorForLogin = yup
    .string()
    .required('Bitte trage Deinen Usernamen oder Deine E-Mail-Adresse ein.');

export const firstNameValidator = yup
    .string()
    .required('Bitte trage Deinen Vornamen ein.');

export const lastNameValidator = (isRequired = true) =>
    isRequired
        ? yup.string().required('Bitte trage Deinen Nachnamen ein.')
        : yup.string();

export const emailValidator = yup
    .string()
    .email('Bitte gib eine gültige E-Mail-Adresse ein.')
    .required('Bitte trage Deine E-Mail-Adresse ein.');

export const passwordValidator = (isRequired) =>
    isRequired
        ? yup
              .string()
              .required('Bitte trage Dein Passwort ein.')
              .min(6, 'Dein Passwort muss mindestens 6 Zeichen enthalten.')
        : yup
              .string()
              .transform((value) => (!value ? undefined : value))
              .min(6, 'Dein Passwort muss mindestens 6 Zeichen enthalten.');

export const passwordConfirmationValidator = yup
    .string()
    .transform((value) => (!value ? undefined : value))
    .test('password-match', 'Die Passwörter stimmen nicht überein.', function (
        value
    ) {
        return value === this.parent.password;
    });

export const salutationValidator = yup
    .object()
    .typeError('Bitte wähle eine Ansprache aus.')
    .required('Bitte wähle eine Ansprache aus.');

export const pharmacyValidator = (isSalesAgent = false) => {
    const msg = isSalesAgent
        ? 'Bitte wähle eine Apotheke aus.'
        : 'Bitte wähle Deine Apotheke aus.';
    return yup.object().typeError(msg).required(msg);
};

export const pharmacyUsersValidator = yup
    .array()
    .typeError('Bitte wähle einen Apothekenmitarbeiter/in aus.')
    .required('Bitte wähle einen Apothekenmitarbeiter/in aus.');

export const reasonPointsAssignmentValidator = yup
    .object()
    .typeError('Bitte wähle den Anlass der Punktvergabe.')
    .required('Bitte wähle den Anlass der Punktvergabe.');

export const identificationNumberValidator = yup
    .string()
    .required('Bitte trage Deine Kundennummer/BGA Nummer ein.');

export const zipCodeValidator = yup
    .string()
    .required('Bitte trage die Postleitzahl Deiner Apotheke ein.')
    .matches(
        /\b(?!01000)(?!99999)(0[1-9]\d{3}|[1-9]\d{4})\b/,
        'Bitte gib eine gültige Postleitzahl ein.'
    );

export const termsOfUseAndPrivacyPolicyValidator = yup
    .boolean()
    .required(
        'Bitte stimme den Nutzungsbedingungen und Datenschutzbestimmungen zu.'
    )
    .oneOf(
        [true],
        'Bitte stimme den Nutzungsbedingungen und Datenschutzbestimmungen zu.'
    );

export const termsOfUseValidator = yup
    .boolean()
    .required('Bitte stimme den Nutzungsbedingungen zu.')
    .oneOf([true], 'Bitte stimme den Nutzungsbedingungen zu.');

export const sharePointsValidator = yup
    .boolean()
    .required(
        'Bitte stimme zu, dass Arbeitskollegen Deinen Punktestand einsehen können.'
    )
    .oneOf(
        [true],
        'Bitte stimme zu, dass Arbeitskollegen Deinen Punktestand einsehen können.'
    );

export const messageValidator = yup
    .string()
    .required('Bitte trage eine Nachricht ein.');

export const categoriesValidator = yup
    .object()
    .test(
        'at least one preference selected',
        'Bitte wähle mindestens ein Thema aus.',
        (preferences) => {
            return Object.values(preferences).some((value) => value === true);
        }
    );

export const reasonSubmissionEvaluationValidator = yup
    .object()
    .typeError('Bitte wähle mindestens einen Grund aus.')
    .required('Bitte wähle mindestens einen Grund aus.');

export const userTypeValidator = yup
    .string()
    .required('Bitte wähle Deine Berufsbezeichnung aus.');

export const feedbackValidator = yup
    .string()
    .required('Bitte wähle eine Option aus.');
