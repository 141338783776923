import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import lang from '../../../../lang/point-limit.lang';
import {Container, Row, Column, Section} from '../../../layout/Grid';
import {ReactComponent as StarsSvg} from '../../../../assets/images/trainings/img-overlay--punkte.svg';
import Link from '../../../components/Link';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import {close as closePointLimitLightbox} from '../../../../model/point-limit-lightbox';

export const PointLimitMessage = ({theme, onButtonClick}) => {
    return (
        <Section theme={theme} noPadding>
            <Container width="narrow" className="text-center">
                <Row>
                    <Column xs={12}>
                        <StarsSvg />
                        <Headline fontWeight="bold" type="h5">
                            {lang.pointLimitMessage.headline}
                        </Headline>
                        <Paragraph size="small">
                            {lang.pointLimitMessage.text}
                        </Paragraph>
                        <Link
                            href={lang.pointLimitMessage.buttonHref}
                            onClick={onButtonClick}
                            showAsButton
                            isInternal>
                            {lang.pointLimitMessage.button}
                        </Link>
                    </Column>
                </Row>
            </Container>
        </Section>
    );
};

PointLimitMessage.propTypes = {
    theme: PropTypes.oneOf(sectionThemeNames),
    onButtonClick: PropTypes.func.isRequired,
};

PointLimitMessage.defaultProps = {
    theme: 'default',
};

export default function ConnectedPointLimitMessage(props) {
    const dispatch = useDispatch();
    return (
        <PointLimitMessage
            {...props}
            onButtonClick={() => dispatch(closePointLimitLightbox())}
        />
    );
}
