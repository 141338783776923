import React from 'react';

import {Column, Container, Row} from '../../../layout/Grid';
import Paragraph from '../../../components/Paragraph';
import lang from '../../../../lang/pages/profil.lang';
import {Headline} from '../../../components/Toast/Notification.styles';
import Button from '../../../components/Button';
import {routes} from '../../../../model/navigation';
import {navigate} from 'gatsby';
import {setReferralCodePassive} from '../../../../model/registration';
import {useDispatch} from 'react-redux';

export const ReferralCodeMessage = () => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setReferralCodePassive());
        navigate(routes.trainings);
    };

    return (
        <Container width="narrow" className="text-center">
            <Row>
                <Column xs={12}>
                    <Headline margin="0">
                        {lang.referralCodeMessage.headline}
                    </Headline>
                </Column>
            </Row>
            <Row>
                <Column xs={12}>
                    <Paragraph margin="0">
                        {lang.referralCodeMessage.paragraph}
                    </Paragraph>
                </Column>
            </Row>
            <Row>
                <Column xs={12}>
                    <Button
                        label={lang.referralCodeMessage.button}
                        onClick={() => handleClick()}></Button>
                </Column>
            </Row>
        </Container>
    );
};

export default ReferralCodeMessage;
