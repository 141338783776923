import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper, Text, SmallText} from './styles';
import Section from '../Grid/Section';
import Container from '../Grid/Container';
import Row from '../Grid/Row';
import Column from '../Grid/Column';
import SitemapNav from '../Navigation/SitemapNav';
import Logo from '../Logo';
import {linkValidation} from '../../../utils/validator-utils';
import lang from './lang';

const Footer = ({sitemapNavItems}) => {
    return (
        <Wrapper>
            <Section>
                <Container width="medium">
                    <Row>
                        <Column lg={4}>
                            <Logo withSubtitle={false} />
                            <Text>{lang.subline}</Text>
                        </Column>
                        <Column lg={8}>
                            <SitemapNav items={sitemapNavItems} />
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <SmallText>{lang.copyright}</SmallText>
                        </Column>
                    </Row>
                </Container>
            </Section>
        </Wrapper>
    );
};

Footer.propTypes = {
    sitemapNavItems: PropTypes.arrayOf(
        PropTypes.shape({
            category: PropTypes.string,
            items: linkValidation(),
        })
    ),
};

export default Footer;
