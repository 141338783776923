import React from 'react';
import DefaultLink, {Link} from '../ui/components/Link';
import Slide from '../ui/components/Slider/Slide';

/**
 * Custom prop type validation for Links
 *
 * Not needed for now
 */
export const linkValidation = () => (props, propName, componentName) => {
    const prop = props[propName];
    let error = null;
    React.Children.forEach(prop, function (child) {
        if (child.type !== DefaultLink && child.type !== Link) {
            error = new Error(
                '`' +
                    componentName +
                    '` children should be of type `Link` oder `DefaultLink`.'
            );
        }
    });
    return error;
};

/**
 * Custom prop type validation for Slides
 */
export const slideValidation = () => (props, propName, componentName) => {
    const prop = props[propName];
    let error = null;
    React.Children.forEach(prop, function (child) {
        if (child.type !== Slide) {
            error = new Error(
                '`' +
                    componentName +
                    '` children should be of type `Link` oder `DefaultLink`.'
            );
        }
    });
    return error;
};
