import {routes} from '../model/navigation/routes.js';

export default {
    pointLimitMessage: {
        headline: 'Maximale Punktzahl erreicht',
        text:
            'Du hast die Maximalpunktzahl erreicht. Bevor Du weitere Punkte sammeln kannst, tausche diese bitte gegen eine Prämie ein.',
        button: 'Zum Shop',
        buttonHref: routes.praemien,
    },
};
